import { ActivatableMapMarker } from '@/mixins/googleMaps/ActivatableMapMarker'
import { Component } from 'vue-property-decorator'
declare const google: any

@Component({
  name: 'ClusterMarker',
})
export default class ClusterMarker extends ActivatableMapMarker {
  markerCssClass: string = 'map-marker-overlay--cluster'
  map: any
  latitude: number
  longitude: number

  onClick = () => {
    this.map.setZoom(this.map.getZoom() + 1)
    this.map.setCenter(new google.maps.LatLng(this.latitude, this.longitude))
  }
}
