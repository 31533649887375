import { GoogleLatLng, MapMarkerOverlayOptions } from '@/types/google'
declare const google: any

interface Property {
  selected: boolean
  preferred: boolean
  cluster: boolean
  small: boolean
}

class MapMarkerOverlay extends google.maps.OverlayView {
  position: GoogleLatLng
  markerCssClass: string
  divElement: HTMLDivElement
  iconElement: HTMLDivElement
  onClick: VoidFunction

  properties: Property

  constructor(options: MapMarkerOverlayOptions, map: any) {
    super()
    this.properties = <Property>{}
    this.position = new google.maps.LatLng(
      options.position.lat,
      options.position.lng
    )
    this.onClick = options.onClick
    this.setCssOptions(options)

    this.setMap(map)
  }

  overlayWrapperCssClassNames(): string {
    let cssClassNames: Array<string> = ['map-marker-overlay-wrapper']
    if (this.properties.selected)
      cssClassNames.push('map-marker-overlay-wrapper--active')
    if (this.properties.cluster)
      cssClassNames.push('map-marker-overlay-wrapper--cluster')
    if (this.properties.small) cssClassNames.push('small')

    return cssClassNames.join(' ')
  }

  markerIconCssClassNames(): string {
    let cssClassNames: Array<string> = [
      'map-marker-overlay',
      this.markerCssClass,
    ]
    if (this.properties.selected)
      cssClassNames.push('map-marker-overlay--active')
    if (this.properties.preferred)
      cssClassNames.push('map-marker-overlay--double-ring')
    else cssClassNames.push('map-marker-overlay--single-ring')

    return cssClassNames.join(' ')
  }

  setCssOptions(options: MapMarkerOverlayOptions) {
    if ('markerCssClass' in options)
      this.markerCssClass = options.markerCssClass
    if ('selected' in options) this.properties.selected = options.selected
    if ('preferred' in options) this.properties.preferred = options.preferred
    if ('cluster' in options) this.properties.cluster = options.cluster
    if ('small' in options) this.properties.small = options.small
  }

  update(options: MapMarkerOverlayOptions) {
    this.setCssOptions(options)
    this.updateStyles()
  }

  updateStyles(): void {
    if (!this.divElement) return
    this.divElement.className = this.overlayWrapperCssClassNames()

    if (this.properties.selected) this.divElement.style.zIndex = '2'
    else this.divElement.style.zIndex = '1'
    this.iconElement.className = this.markerIconCssClassNames()
  }

  onAdd(): void {
    this.divElement = document.createElement('div')
    this.divElement.className = this.overlayWrapperCssClassNames()

    this.iconElement = document.createElement('div')
    this.divElement.appendChild(this.iconElement)
    this.updateStyles()

    google.maps.event.addDomListener(this.divElement, 'click', this.onClick)
    this.getPanes().overlayMouseTarget.appendChild(this.divElement)

    // just a hack to fade in overlay
    if (this.divElement !== null)
      setTimeout(() => {
        if (this.divElement) this.divElement.style.opacity = '1'
      })
  }

  draw(): void {
    let point = this.getProjection().fromLatLngToDivPixel(this.position)
    if (point) {
      this.divElement.style.left = point.x + 'px'
      this.divElement.style.top = point.y + 'px'
    }
  }

  remove(): void {
    this.setMap(null)
    if (this.divElement) {
      google.maps.event.clearInstanceListeners(this.divElement, 'click')
      this.divElement.parentElement.removeChild(this.divElement)
      this.divElement = null
    }
  }
}

export default MapMarkerOverlay
export { MapMarkerOverlay }
