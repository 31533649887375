
import { Vue, Component, Watch } from 'vue-property-decorator'
import { injectGoogleMapsScriptTag } from '@/helpers/whenGoogleMap'
import { UserConsentConstants } from '@/types/user_consent'

@Component({ name: 'MapsScript' })
export default class MapsScript extends Vue {
  googleMapsLoaded: boolean = false
  googleMapsLoading: boolean = false

  @Watch('$store.state.clientInformation.googleApiKey')
  injectMaps() {
    if (
      !this.$store.state.clientInformation.googleApiKey ||
      this.googleMapsLoading
    ) {
      return
    }
    console.log('MapsScript: injectGoogleMapsScript')
    this.googleMapsLoading = true
    injectGoogleMapsScriptTag(
      this.$store.state.clientInformation.googleApiKey,
      (maps: any) => {
        // console.log(maps)
        this.googleMapsLoaded = true
        this.$store.commit(UserConsentConstants.googleMapsLoaded, true)
      }
    )
  }

  created() {
    this.injectMaps()
  }
}
