
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ExternalLink from '@/assets/icons/link_external.svg'
@Component({
  components: { ExternalLink },
})
export default class ButtonLink extends Vue {
  @Prop() href: string
  @Prop() text: string
}
