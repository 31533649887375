import { ClientConfigurationRequest } from '@/api/client_configuration_request'
import { clusterPrecisionForZoom } from '@/api/cluster_precision'
import { PlacesBoundsRequest } from '@/api/places_bounds_request'
import { PlacesClustersRequest } from '@/api/places_clusters_request'
import { PlacesRequest } from '@/api/places_request'
import { FrameCommunication } from '@/helpers/FrameCommunication'
import { whenGoogleMapsReady } from '@/helpers/whenGoogleMap'
import { availableFilters } from '@/mixins/apiContentDecorator'
import { PlaceResult, PlacesClusterResult } from '@/types/api'
import { GoogleLatLng } from '@/types/google'
import { Bounds, LatitudeLongitude, MarkerElement } from '@/types/marker'
import { Coords } from '@/types/navigator.geolocation'
import { cloneDeep, uniqBy } from 'lodash'
import pickBy from 'lodash.pickby'
import Vue from 'vue'
import VueResource from 'vue-resource'
import Vuex from 'vuex'
import { UserConsentOptState } from '@/types/user_consent'
import { BankUrlRequest } from '@/api/bank_url_request'

declare const google: any

Vue.use(Vuex)
Vue.use(VueResource)

const userAgentMobile: boolean =
  null !==
  (navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i))

const initialSearchCenter: LatitudeLongitude = {
  latitude: 50.733865,
  longitude: 7.1000471,
}

const defaultAvailableFilters = availableFilters.reduce(
  (acc: any, e: string) => {
    acc[e] = null
    return acc
  },
  {}
)

defaultAvailableFilters['kind'] = ['bank', 'atm']

export interface SelectedElement {
  id: string
  coords: LatitudeLongitude
}

interface BoundsWithCenter {
  bounds: Bounds
  center: LatitudeLongitude
  zoomLevel: number
}

export default new Vuex.Store({
  state: {
    // opt stage
    userOptedIn: false,
    userConsentOptState: UserConsentOptState.None,
    boundsChangeEnabled: false,
    googleMapsLoaded: false,
    initialPanelUnUsed: false,
    // client
    clientInformation: {
      requestedAddress: null,
      renderInitialPanel: true,
      autofit: false,
      width: 0,
      userAgentMobile: userAgentMobile,
      consentEnabled: undefined,
      consentSkipped: false,
      privacyUrl: null,
      bankCode: null,
      bankUrl: null,
      apiToken: process.env.VUE_APP_DEFAULT_API_TOKEN,
      googleApiKey: '',
      allowedLocationSearchDomains: [],
      preferredBankCode: '',
      deeplinkId: null,
      deeplinkTag: null,
      kindOptions: ['bank', 'atm', 'cashback'],
      center: {
        lat: 51.2130135,
        lng: 10.6644228,
      },
      bounds: null,
      skipLocationDetection: false,
      tracker: null,
      template: null,
    },
    placesRequest: {
      mode: 'null',
      data: <Array<PlaceResult>>[],
      page: 1,
    },
    placesClustersRequest: {
      data: <Array<PlacesClusterResult>>[],
    },
    searchInput: '',
    searchCenter: initialSearchCenter,
    sortCenter: { ...initialSearchCenter },
    position: null,
    selectedElement: <SelectedElement>null,
    google: {
      zoomLevel: 6,
      bounds: <Bounds>null,
      center: { latitude: null, longitude: null },
    },
    locked: false,
    filters: defaultAvailableFilters,
  },
  getters: {
    deeplinkParams(state: any) {
      return pickBy({
        _deeplink_id: state.clientInformation.deeplinkId,
        _deeplink_tag: state.clientInformation.deeplinkTag,
      })
    },
    filterParams(state: any) {
      return pickBy(state.filters, (value: boolean) => value)
    },
    sortCenterParams(state) {
      if (
        state.sortCenter.latitude === state.searchCenter.latitude &&
        state.sortCenter.longitude === state.searchCenter.longitude
      )
        return {}

      return {
        _sort_center_latitude: state.sortCenter.latitude,
        _sort_center_longitude: state.sortCenter.longitude,
      }
    },
    placesRequestParams(state, getters) {
      const locationParams: any = {}

      if (!state.googleMapsLoaded && state.searchInput) {
        locationParams._address = state.searchInput
      } else {
        locationParams._latitude = state.searchCenter.latitude
        locationParams._longitude = state.searchCenter.longitude
      }

      return {
        ...locationParams,
        ...getters.sortCenterParams,
        _per_page: 10,
        _page: state.placesRequest.page,
        ...getters.filterParams,
        _radius: 20_000, // whole planet
        ...getters.deeplinkParams,
        _fields: [
          'id',
          'address',
          'contact',
          'kind',
          'subtype',
          'links',
          'name',
          'services',
          'opening_hours',
          'measure_code',
          'is_open',
          'institute',
          'branch_name',
          'uid_vrnet',
          'alternative_bank_name',
          'opening_hours_hint',
        ],
      }
    },
    placesClustersRequestParams(state, getters) {
      // console.log('zoom', state.google.zoomLevel, 'precision', clusterPrecisionForZoom(state.google.zoomLevel))
      return {
        _latitude: state.searchCenter.latitude,
        _longitude: state.searchCenter.longitude,
        ...getters.sortCenterParams,
        _precision: clusterPrecisionForZoom(state.google.zoomLevel),
        _southWest: state.google.bounds.southWest,
        _northEast: state.google.bounds.northEast,
        ...getters.filterParams,
      }
    },
    placesBoundsRequestParams(state, getters) {
      return getters.filterParams
    },
    searchCenterLockedOnPosition(state, getters) {
      return !!state.position || state.locked
    },
  },
  mutations: {
    setInitialPanelUnUsed(state: any, value: Boolean) {
      state.initialPanelUnUsed = value
    },
    googleMapsLoaded(state: any, value: Boolean) {
      state.googleMapsLoaded = value
    },
    setBoundsChangeEnabled(state: any, value: Boolean) {
      state.boundsChangeEnabled = value
    },
    setUserConsentOptState(state: any, value: UserConsentOptState) {
      state.userConsentOptState = value
      state.userOptedIn = value === UserConsentOptState.OptIn
    },
    setGoogleZoomLevel(state: any, value: number) {
      state.google.zoomLevel = value
    },
    setGoogleCenter(state: any, value: GoogleLatLng) {
      state.google.center = value
    },
    setGoogleBounds(state: any, bounds: Bounds) {
      state.google.bounds = cloneDeep(bounds)
    },
    setSearchInput(state: any, value: string) {
      state.searchInput = value
    },
    setClientInformation(
      state: any,
      data: { name: string; value: string | number }
    ) {
      if (
        data.value === '' ||
        !Object.keys(state.clientInformation).includes(data.name)
      )
        state.filters[data.name] = null
      else state.clientInformation[data.name] = data.value
    },
    setSelectedTypes(state: any, value: Array<string>) {
      state.filters.selectedTypes = value
    },
    setClientWidth(state: any, value: number) {
      state.clientInformation.width = value
    },
    setFilterValueByName(
      state: any,
      data: { name: string; value: Array<string> | string | boolean }
    ) {
      if (data.value === '' || data.value === false)
        state.filters[data.name] = null
      else state.filters[data.name] = JSON.parse(JSON.stringify(data.value))
    },
    setSelectedElement(state: any, selectedElement: SelectedElement) {
      if (process.env.VUE_APP_DEBUG === 'true')
        console.log('select element', selectedElement)
      state.selectedElement = selectedElement
      if (state.selectedElement)
        state.selectedElement.eventTime = new Date().getTime()
    },
    setPlaces(state: any, places) {
      state.placesRequest.data = places
    },
    setPlacesClusters(state: any, placesClusters) {
      state.placesClustersRequest.data = placesClusters
    },
    setSearchCenter(state: any, data: LatitudeLongitude) {
      state.searchCenter.latitude = data.latitude
      state.searchCenter.longitude = data.longitude
    },
    setSortCenter(state: any, data: LatitudeLongitude) {
      state.sortCenter.latitude = data.latitude
      state.sortCenter.longitude = data.longitude
    },
    setPosition(state: any, position: LatitudeLongitude | Coords) {
      state.position = {
        latitude: position.latitude,
        longitude: position.longitude,
      }
    },
    setPlacesRequestMode(state: any, mode: string) {
      state.placesRequest.mode = mode
    },
  },
  actions: {
    async boundsChanged(
      { commit, dispatch, getters, state },
      data: BoundsWithCenter
    ) {
      if (process.env.VUE_APP_DEBUG === 'true') console.log('bounds change')
      commit('setGoogleBounds', data.bounds)
      commit('setGoogleZoomLevel', data.zoomLevel)
      commit('setGoogleZoomLevel', Math.min(17, data.zoomLevel))

      // commit('setGoogleCenter', data.center)
      commit('setSortCenter', data.center)
      if (!getters.searchCenterLockedOnPosition) {
        commit('setSearchCenter', data.center)
      }

      dispatch('loadPlacesClusters')
      dispatch('updatePlaces')
    },
    async initialPlacesLoad({ commit, state, getters }) {
      if (process.env.VUE_APP_DEBUG === 'true') console.log('init places load')
      const placesRequest = new PlacesRequest({ store: this })
      const results = await placesRequest.get(getters.placesRequestParams)
      if (results && results.length > 0) commit('setPlaces', results)
    },

    async changePosition(
      { commit, state, getters },
      position: LatitudeLongitude
    ) {
      if (process.env.VUE_APP_DEBUG === 'true') console.log('position change')
      commit('setPosition', position)
      commit('setSearchCenter', position)
      commit('setSortCenter', position)
      commit('setGoogleCenter', position)
      commit('setGoogleZoomLevel', 15)
      commit('setSelectedElement', <SelectedElement>null)

      this.dispatch('updatePlaces')
    },
    async loadPlacesClusters({ commit, state, getters }) {
      if (!state.google.bounds || !state.google.bounds.northEast) return

      const placesClustersRequest = new PlacesClustersRequest({ store: this })
      const results = await placesClustersRequest.get(
        getters.placesClustersRequestParams
      )
      commit('setPlacesClusters', results)
    },
    async activateElementThroughMarker(
      { commit, state, getters },
      markerData: MarkerElement
    ) {
      let coords: LatitudeLongitude
      let place: PlaceResult

      if (markerData.count === 1)
        coords = {
          latitude: markerData.latitude,
          longitude: markerData.longitude,
        }

      if (!coords) {
        place = state.placesRequest.data.find(
          (place: PlaceResult) => place.id === markerData.mainId
        )
        if (place)
          coords = {
            latitude: parseFloat(place.address.latitude),
            longitude: parseFloat(place.address.longitude),
          }
      }

      if (!coords) {
        const places: Array<PlaceResult> = await new PlacesRequest({
          store: this,
        }).get({ _limit: 1, id: markerData.mainId })
        if (places)
          coords = {
            latitude: parseFloat(places[0].address.latitude),
            longitude: parseFloat(places[0].address.longitude),
          }
      }

      if (!coords) return

      const selectedElement: SelectedElement = {
        id: markerData.mainId,
        coords: coords,
      }
      commit('setSelectedElement', selectedElement)
      if (!getters.searchCenterLockedOnPosition)
        commit('setSearchCenter', coords)

      commit('setSortCenter', coords)

      FrameCommunication.postPlace(place)

      state.placesRequest.page = 1
      if (state.placesRequest.mode === 'sidebar') {
        const placesRequest = new PlacesRequest({ store: this })
        const results = await placesRequest.get(getters.placesRequestParams)
        commit('setPlaces', results)
      }
    },
    navigationCenterChange(
      { commit, state, getters, dispatch },
      data: { zoomLevel: number; googleCoords: GoogleLatLng }
    ) {
      if (!getters.searchCenterLockedOnPosition)
        commit('setSearchCenter', state.selectedElement)

      commit('setSortCenter', state.selectedElement)
      commit('setGoogleCenter', data.googleCoords)
      if (data.zoomLevel) commit('setGoogleZoomLevel', data.zoomLevel)
    },

    async updatePlaces({ commit, state, getters }) {
      if (process.env.VUE_APP_DEBUG === 'true') console.log('updatePlaces')
      const placesRequest = new PlacesRequest({ store: this })
      state.placesRequest.page = 1
      const results = await placesRequest.get(getters.placesRequestParams)
      commit('setPlaces', results)
    },
    async updatePlacesForBounds({ commit, state, getters }) {
      if (process.env.VUE_APP_DEBUG === 'true')
        console.log('updatePlacesForBounds')
      const placesRequest = new PlacesRequest({ store: this })
      state.placesRequest.page = 1
      const params = getters.placesRequestParams
      try {
        delete params._address
      } catch (e) {}
      const results = await placesRequest.get(params)
      commit('setPlaces', results)
    },

    async increasePlacesList({ commit, state, getters }) {
      if (process.env.VUE_APP_DEBUG === 'true')
        console.log('loading more elements')
      state.placesRequest.page++
      const placesRequest = new PlacesRequest({ store: this })
      const results = await placesRequest.get(getters.placesRequestParams)
      if (results && results.length > 0)
        commit(
          'setPlaces',
          uniqBy(state.placesRequest.data.concat(results), 'id')
        )
    },
    async getPlacesBounds({ commit, getters }) {
      if (process.env.VUE_APP_DEBUG === 'true') console.log('getPlacesBounds')
      const placesBoundsRequest = new PlacesBoundsRequest({ store: this })
      const bounds = await placesBoundsRequest.get(
        getters.placesBoundsRequestParams
      )

      if (bounds) {
        await whenGoogleMapsReady()
        commit('setClientInformation', {
          name: 'bounds',
          value: [
            new google.maps.LatLng(
              bounds.northEast.latitude,
              bounds.northEast.longitude
            ),
            new google.maps.LatLng(
              bounds.southWest.latitude,
              bounds.southWest.longitude
            ),
          ],
        })
      } else {
        console.log('no bounds found')
        commit('setClientInformation', { name: 'autofit', value: false })
      }
    },
    async getClientConfiguration({ commit }) {
      const clientConfigurationRequest = new ClientConfigurationRequest({
        store: this,
      })
      const response = await clientConfigurationRequest.get()

      commit('setClientInformation', {
        name: 'privacyUrl',
        value: response.privacy_url,
      })
      commit('setClientInformation', {
        name: 'googleApiKey',
        value: response.google_api_key_frontend,
      })
      commit('setClientInformation', {
        name: 'allowedLocationSearchDomains',
        value: response.allowed_location_search_domains,
      })
      commit('setClientInformation', {
        name: 'tracker',
        value: response.tracker,
      })
      commit('setClientInformation', {
        name: 'template',
        value: response.template,
      })

      const val = Number(response.google_consent_disabled) != 1
      commit('setClientInformation', { name: 'consentEnabled', value: val })
      if (!val) {
        commit('setClientInformation', { name: 'consentSkipped', value: true })
      }
    },

    async getBankUrl({ commit }) {
      const bankUriRequest = new BankUrlRequest({ store: this })
      commit('setClientInformation', {
        name: 'bankUrl',
        value: await bankUriRequest.get(),
      })
    },
  },
})
