import { Component, Vue } from 'vue-property-decorator'
import { PlacesClustersRequestParams, PlacesClusterResult } from '@/types/api'
import { baseUrlCreator } from '@/helpers/baseUrlCreator'
const baseUrl = baseUrlCreator()

@Component
export class PlacesClustersRequest extends Vue {
  async get(
    params: PlacesClustersRequestParams
  ): Promise<Array<PlacesClusterResult>> {
    const response = await this.$http.get(baseUrl + '/placesCluster', {
      headers: { token: this.$store.state.clientInformation.apiToken },
      params,
    })

    return response.data || []
  }
}

export default PlacesClustersRequest
