
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator'
import TypeChooser from './formFilters/TypeChooser.vue'
import CheckboxFilter from './formFilters/CheckboxFilter.vue'
import ServiceFilters from './formFilters/ServiceFilters.vue'
import ApplyFilterButton from './formFilters/ApplyFilterButton.vue'
import pick from 'lodash.pick'

@Component({
  components: {
    TypeChooser,
    CheckboxFilter,
    ServiceFilters,
    ApplyFilterButton,
  },
})
export default class FormFilters extends Vue {
  plainFilterElements: Array<string> = ['kind', '_is_open']
  services = {}
  kind: Array<string> = []
  is_open: boolean = false

  updateService() {
    this.availableServiceNamesForTheSelectedKinds.forEach(
      function (element: string) {
        const service_string = 'services.' + element
        this.$set(
          this.services,
          element,
          this.$store.state.filters[service_string] || false
        )
      }.bind(this)
    )
  }

  mounted(): void {
    this.plainFilterElements.forEach((element: string) => {
      ;(
        this as {
          [key: string]: any
        }
      )[element.replace(/^_/, '')] = this.$store.state.filters[element]
    })
    this.updateService()
  }

  checkboxValueChange(data: any) {
    const key = Object.keys(data)[0]
    this.$data[key] = data[key]
  }

  serviceValueChange(data: any) {
    const key = Object.keys(data)[0]
    this.$data.services[key] = data[key]
  }

  get availableServiceNamesForTheSelectedKinds(): Array<string> {
    if (this.$data.kind.includes('bank'))
      return [
        'personal_advice',
        'atm',
        'self_service_terminal',
        'deposit_machine',
        'coin_counter',
        'free_parking',
        'wheelchair_accessible',
      ]
    else return []
  }

  get availableServicesForTheSelectedKinds(): any {
    return pick(
      this.$data.services,
      this.availableServiceNamesForTheSelectedKinds
    )
  }

  @Emit('apply-filter')
  commitToStore() {
    console.log('apply-filter!!')
    this.plainFilterElements.forEach((element: string) => {
      this.$store.commit('setFilterValueByName', {
        name: element,
        value: this.$data[element.replace(/^_/, '')],
      })
    })
    this.availableServiceNamesForTheSelectedKinds.forEach((element: string) => {
      const service_string = 'services.' + element
      this.$store.commit('setFilterValueByName', {
        name: service_string,
        value: this.availableServicesForTheSelectedKinds[element] || false,
      })
    })
    this.$store.dispatch('updatePlaces')
  }
}
