// define within this module every marker which is renderable
// this is used to apply a clean import for clustering components
export default {
  beforeCreate(): void {
    this.$options.components.BankMarker = require('./BankMarker').default
    this.$options.components.AtmMarker = require('./AtmMarker').default
    this.$options.components.CashbackMarker =
      require('./CashbackMarker').default
    this.$options.components.DefaultMarker = require('./DefaultMarker').default
    this.$options.components.ClusterMarker = require('./ClusterMarker').default
    this.$options.components.LocationMarker =
      require('./LocationMarker').default
  },
}
