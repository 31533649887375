
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator'

import CheckSvg from '@/assets/icons/haken.svg'

@Component({
  components: {
    CheckSvg,
  },
})
export default class CheckboxFilter extends Vue {
  @Prop() checked: boolean
  @Prop() name: string

  @Emit()
  valueChange(event: any): any {
    let obj: { [key: string]: boolean } = {}
    obj[this.name] = event.target.checked
    return obj
  }
}
