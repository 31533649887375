export function clusterPrecisionForZoom(zoom: number): number {
  switch (zoom) {
    case 20:
      return 9
    case 19:
      return 9
    case 18:
      return 8
    case 17:
      return 8
    case 16:
      return 7
    case 15:
      return 7
    case 14:
      return 6
    case 13:
      return 6
    case 12:
      return 5
    case 11:
      return 5
    case 10:
      return 4
    case 9:
      return 4
    case 8:
      return 3
    case 7:
      return 3
    case 6:
      return 3
    case 5:
      return 2
    case 4:
      return 1
    case 3:
      return 1
    case 2:
      return 1
    case 1:
      return 1
  }
  // zoom > 20
  return 10
}
