import { Component, Vue } from 'vue-property-decorator'
import { ClientConfiguration } from '@/types/api'
import { baseUrlCreator } from '@/helpers/baseUrlCreator'
import Vuex from 'vuex'
Vue.use(Vuex)
const baseUrl = baseUrlCreator()

@Component
export class ClientConfigurationRequest extends Vue {
  async get(): Promise<ClientConfiguration> {
    const response = await this.$http.get(baseUrl + '/clientConfiguration', {
      headers: {
        token: this.$store.state.clientInformation.apiToken,
      },
    })

    return response.data
  }
}
