var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sidebar-panel-content--container",attrs:{"id":"sidebar-panel-content"}},[_c(_vm.formHeaderComponent,{tag:"component",staticClass:"px-3 py-3 background-color-transition",class:{
      hide: !_vm.initial && !_vm.animationMoveSuccess,
      invertColor: !_vm.showFormFilters,
      'theme-bg-primary': _vm.showFormFilters,
    },attrs:{"use-google-maps":_vm.useGoogleMaps,"showOptionButton":true,"open":_vm.showFormFilters},on:{"toggle-filters":function($event){_vm.showFormFilters = !_vm.showFormFilters},"input-change":_vm.changeLocation,"position-change":_vm.changeLocationByPosition,"update-current-position":function($event){return _vm.$emit('update-current-position', $event)}}}),_c('FormFilters',{staticClass:"sidebar-panel-content--form-filters",class:{
      invertColor: !_vm.showFormFilters,
      'filter-closed': !_vm.showFormFilters,
      'filter-open': _vm.showFormFilters,
    },on:{"apply-filter":function($event){_vm.showFormFilters = !_vm.showFormFilters}}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.results && _vm.results.length > 0 && _vm.animationMoveSuccess)?_c('div',[_c('ResultList',{attrs:{"results":_vm.results},on:{"changeLocationToResult":_vm.changeLocationToResult}})],1):_c('ResultNotFound',{staticClass:"px-3 mt-3"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }