const availableFilters: Array<string> = [
  '_is_open',
  'address.city',
  'address.district',
  'address.zip_code',
  'bank_code',
  'consulting_topics',
  'uid_vrnet',
  'facility_type',
  'institute.id',
  'institute.bank_type',
  'institute.young_creative',
  'kind',
  'services.account_info_service_network',
  'services.atm',
  'services.bank_statement_printer',
  'services.bankcard_pin_changeable',
  'services.bankcard_service_network',
  'services.cash_box',
  'services.coin_counter',
  'services.coin_roll_dispenser',
  'services.credit_transfer_scanner',
  'services.deposit_machine',
  'services.free_parking',
  'services.letter_locker',
  'services.mobile_card_charging',
  'services.night_depository',
  'services.personal_advice',
  'services.post_service',
  'services.safe_deposit_box',
  'services.self_service_terminal',
  'services.vr_ident',
  'services.vr_mobile_cash',
  'services.wheelchair_accessible',
  'alternative_bank_name',
  'opening_hours_hint',
]

const allKinds: Array<string> = ['bank', 'atm', 'cashback']

type deepStringArray = Array<Array<string>>

function servicesMapper(service: string, kind?: string) {
  switch (service) {
    case 'account_info_service_network':
      return 'BankCard KontoInfo'
    case 'atm':
      return 'Geldautomat'
    case 'bank_statement_printer':
      return 'Kontoauszugsdrucker'
    case 'bankcard_pin_changeable':
      return 'BankCard PIN ändern'
    case 'bankcard_service_network':
      if (kind == 'atm') return 'Teilnahme BankCard ServiceNetz'
      return 'BankCard ServiceNetz'
    case 'cash_box':
      return 'Kasse'
    case 'coin_counter':
      return 'Einzahlautomat für Münzen'
    case 'coin_roll_dispenser':
      return 'Münzrollenausgabe'
    case 'credit_transfer_scanner':
      return 'Überweisungsscanner'
    case 'deposit_machine':
      return 'Einzahlautomat für Scheine'
    case 'free_parking':
      return 'Kostenfreie Parkplätze'
    case 'letter_locker':
      return 'Briefschließfächer'
    case 'mobile_card_charging':
      return 'Handy aufladen'
    case 'night_depository':
      return 'Nachttresor'
    case 'personal_advice':
      return 'Persönliche Beratung'
    case 'post_service':
      return 'Postdienstleistungen'
    case 'safe_deposit_box':
      return 'Bankschließfach'
    case 'self_service_terminal':
      return 'SB-Terminal'
    case 'vr_ident':
      return 'VR-Ident'
    case 'vr_mobile_cash':
      return 'VR-MobileCash'
    case 'wheelchair_accessible':
      return 'Barrierefreier Zugang'

    default:
      return service
  }
}

function kindMapper(kind: string) {
  switch (kind) {
    case 'bank':
      return 'Bank'
    case 'atm':
      return 'Geldautomat'
    case 'cashback':
      return 'Bargeld im Einzelhandel'
  }
}

export { servicesMapper, kindMapper, availableFilters, allKinds }
