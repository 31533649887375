
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import DividingLine from '../../extras/DividingLine.vue'

@Component({
  components: {
    DividingLine,
  },
})
export default class ContentRowWithHr extends Vue {}
