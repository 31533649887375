
import { Component, Vue, Watch } from 'vue-property-decorator'
import { PlacesClusterResult } from '@/types/api'
import { Debounce } from 'lodash-decorators'

@Component
export default class FakeMap extends Vue {
  @Watch('$store.state.placesClustersRequest.data')
  @Debounce(400)
  ensureSelectedElementIsAvailable(): void {
    if (!this.$store.state.selectedElement || this.selectedElementAvailable)
      return

    this.navigateToSelected(18)
  }

  @Watch('$store.state.filters', { deep: true })
  onFiltersChange(): void {
    this.$store.dispatch('loadPlacesClusters')
  }

  @Watch('$store.state.selectedElement', { deep: true })
  selectedElementChange() {
    if (!this.$store.state.selectedElement) return
    this.ensureSelectedElementIsAvailable()
  }

  get selectedElementAvailable(): boolean {
    return this.$store.state.placesClustersRequest.data.some(
      (marker: PlacesClusterResult) => true
    )
  }

  @Debounce(300)
  navigateToSelected(zoom: number = 0): void {
    if (!this.$store.state.selectedElement) return
  }
}
