import { PlaceResult } from '@/types/api'

export class FrameCommunication {
  static postPlace(place: PlaceResult): void {
    if (!place || place.kind != 'bank') return

    const payload: any = {
      type: 'setUserBank',
      params: {
        uid_vrnet: place.uid_vrnet,
      },
    }

    console.log('Event: setUserBank')
    if (process.env.VUE_APP_DEBUG) console.log('Posting Payload', payload)
    window.parent.postMessage(payload, '*')
  }
}
