'use strict'

var HYPHEN_LABEL_CHAR_MAPPING = [
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  'f',
  '-',
  '-',
  '-',
  'fi',
  'fi',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  'y',
  '-',
  '-',
  '-',
  '-',
  'i',
  'c',
  'p',
  'o',
  'v',
  '-',
  's',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  'a',
  'a',
  'a',
  'a',
  'ae',
  'a',
  'ae',
  'c',
  'e',
  'e',
  'e',
  'e',
  'i',
  'i',
  'i',
  'i',
  'd',
  'n',
  'o',
  'o',
  'o',
  'o',
  'oe',
  'x',
  'o',
  'u',
  'u',
  'u',
  'ue',
  'y',
  'b',
  'ss',
  'a',
  'a',
  'a',
  'a',
  'ae',
  'a',
  'ae',
  'c',
  'e',
  'e',
  'e',
  'e',
  'i',
  'i',
  'i',
  'i',
  'o',
  'n',
  'o',
  'o',
  'o',
  'o',
  'oe',
  '-',
  'o',
  'u',
  'u',
  'u',
  'ue',
  'y',
  'b',
  'y',
]

// as provided by https://ticket.i22.de/issues/87318
/**
 * @function createValidName
 * based on Java library JcrUtil.createValidName
 * @param  {String} title
 * @param  {Array<String>} _labelCharMapping default: HYPHEN_LABEL_CHAR_MAPPING
 * @param  {String} _defaultReplacementCharacter default: '_'
 * @return {String} label
 */
function createValidName(
  title,
  _labelCharMapping,
  _defaultReplacementCharacter
) {
  var labelCharMapping =
      Array.isArray(_labelCharMapping) && _labelCharMapping.length === 256
        ? _labelCharMapping
        : HYPHEN_LABEL_CHAR_MAPPING,
    defaultReplacementCharacter =
      typeof _defaultReplacementCharacter === 'string'
        ? _defaultReplacementCharacter
        : '_',
    prevEscaped = false,
    buffer = '',
    char,
    repl,
    i

  if (typeof title !== 'string') {
    return ''
  }

  for (i = 0; i < title.length && buffer.length < 64; i++) {
    char = title.charCodeAt(i)

    repl = defaultReplacementCharacter

    if (labelCharMapping[char]) {
      repl = labelCharMapping[char]
    }

    if (repl === defaultReplacementCharacter) {
      if (!prevEscaped && buffer.length < 16) {
        buffer += defaultReplacementCharacter
      }
      prevEscaped = true
    } else {
      buffer += repl
      prevEscaped = false
    }
  }

  return buffer
}

// https://www.vr.de/standorte/B/Bn-Bz/volksbank-koeln-bonn-eg-filiale-beuel-zentrum-bank-13346.html
// https://www.vr.de/standorte/B/Bn-Bz/volksbank-koeln-bonn-eg-filiale-beuel-zentrum-13346.html

export function adaptedGenerateSeoPageUrl(place) {
  const bank = {
    id: place.id.replace(/\D/g, ''), // "bank-13346" -> "13346"
    name: place.name,
    address: place.address,
  }

  return generateSeoPageUrl(bank)
}

export function adaptedGenerateSeoGAAUrl(place) {
  const atm = {
    id: place.id.replace(/\D/g, ''), // "atm-13346" -> "13346"
    address: place.address,
  }

  return generateSeoGAAUrl(atm)
}

// as provided by https://ticket.i22.de/issues/87318
/**
 * @function generateSeoPageUrl
 * generates a vr.de seo page url by using 'createValidName'
 * @param  {Object} bank [Bank]
 * @return {String} url
 */
function generateSeoPageUrl(bank) {
  var chars,
    char,
    url = []

  // check for new/old api (revisit when finally updating dive api)
  chars = bank && bank.city ? bank.city : bank.address.city
  char = chars[0].toUpperCase()
  url = [char]

  // check for characters, which have an additional url part (f.e. /B/Ba-Bm/ and /B/Bn-Bz)
  // will need to revisit this, when new seo-pages arrive
  if (['B', 'H', 'S', 'W'].indexOf(char) !== -1) {
    if (/[a-m]/i.test(chars[1])) {
      url.push(char + 'a-' + char + 'm')
    } else {
      url.push(char + 'n-' + char + 'z')
    }
  }

  url.push((createValidName(bank.name) + '-' + bank.id).replace(/[-]+/g, '-'))

  return 'https://www.vr.de/standorte' + '/' + url.join('/') + '.html'
}

// as provided by https://ticket.i22.de/issues/87318
/**
 * @function generateSeoGAAUrl
 * generates a vr.de seo page url for atm by using 'createValidName'
 * @param  {Object} bank [Bank]
 * @return {String} url
 */
function generateSeoGAAUrl(atm) {
  var chars,
    char,
    url = []

  // check for new/old api (revisit when finally updating dive api)
  chars = atm && atm.city ? atm.city : atm.address.city
  char = chars[0].toLowerCase()

  // check for characters, which have an additional url part (f.e. /B/Ba-Bm/ and /B/Bn-Bz)
  // will need to revisit this, when new seo-pages arrive
  if (char === 'b') {
    if (/[a-e]/i.test(chars[1])) {
      url.push(char + 'a')
    } else if (/[f-m]/i.test(chars[1])) {
      url.push(char + 'f')
    } else {
      url.push(char + 'n')
    }
  } else if (['h', 's', 'w'].indexOf(char) !== -1) {
    if (/[a-m]/i.test(chars[1])) {
      url.push(char + 'a')
    } else {
      url.push(char + 'n')
    }
  } else {
    url.push(char)
  }

  url.push(
    (
      createValidName(atm.street || atm.address.street + '-' + chars) +
      '-' +
      atm.id
    ).replace(/[-]+/g, '-')
  )

  return 'https://www.vr.de/geldautomaten' + '/' + url.join('/') + '.html'
}
