
import { Component, Vue } from 'vue-property-decorator'
import LocationSvg from '@/assets/icons/location.svg'
import Geolocation from '@/mixins/googleMaps/navigator.geolocation'
@Component({
  name: 'CurrentPosition',
  components: { LocationSvg },
  mixins: [Geolocation],
})
export default class CurrentPosition extends Vue {}
