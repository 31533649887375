var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{style:({
      height: _vm.containerHeight,
      width: _vm.containerWidth,
      marginTop: _vm.containerMarginTop,
      marginLeft: _vm.containerMarginLeft,
    }),attrs:{"id":"map"}}),(_vm.map !== null)?_c('div',[_c('Cluster',{attrs:{"map":_vm.map}}),(_vm.currentPositionMarker !== null)?_c('div',[_c(_vm.currentPositionMarker.type,_vm._b({key:_vm.currentPositionMarker.data.latitude +
          ',' +
          _vm.currentPositionMarker.data.longitude,tag:"component",attrs:{"map":_vm.map}},'component',_vm.currentPositionMarker.data,false))],1):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }