
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator'
import InitialPanelContent from './InitialPanelContent.vue'
import TopbarPanelContent from './TopbarPanelContent.vue'
import SidebarPanelContent from './SidebarPanelContent.vue'
import ZoomLevelSlider from '@/components/ZoomLevelSlider.vue' // @ is an alias to /src™
import CurrentPosition from '@/components/CurrentPosition.vue'

@Component({
  components: {
    InitialPanelContent,
    TopbarPanelContent,
    SidebarPanelContent,
    ZoomLevelSlider,
    CurrentPosition,
  },
})
export default class Panel extends Vue {
  @Prop() results: any
  @Prop() useGoogleMaps: boolean

  $el: HTMLDivElement

  initial: boolean = this.$store.state.clientInformation.renderInitialPanel
  initialPanelUnUsed: boolean = false
  animationMoveSuccess: boolean = false
  displayToggle: boolean = false
  mobileBreakpoint: number = 600
  transitionEnabled: boolean = true

  @Watch('$store.state.clientInformation.width')
  disableTransition() {
    this.transitionEnabled = false
    setTimeout(() => (this.transitionEnabled = true), 150)
  }

  beforeMount() {
    this.initial = !!this.$store.state.clientInformation.renderInitialPanel
    if (this.initial) return
    this.displayToggle = true
    this.animationMoveSuccess = true
  }

  mounted() {
    this.$el.style.height = this.$el.clientHeight + 'px'
    if (!this.initial) {
      this.transformEmpty()
    }
  }

  @Emit()
  transformEmpty(): void {
    this.initialPanelUnUsed = true
    this.$store.commit('setInitialPanelUnUsed', true)
    this.transform()
  }

  @Watch('$store.state.clientInformation.renderInitialPanel')
  transform(): void {
    this.$el.style.height = ''
    this.initial = false
    setTimeout(() => (this.displayToggle = true), 500)
    setTimeout(() => (this.animationMoveSuccess = true), 750)
  }

  get mobileView(): boolean {
    return this.$store.state.clientInformation.width <= this.mobileBreakpoint
  }

  get currentComponent(): string {
    if (this.initial) return 'InitialPanelContent'
    else if (this.mobileView) return 'TopbarPanelContent'
    else return 'SidebarPanelContent'
  }
}
