export enum UserConsentOptState {
  None,
  OptIn,
  OptOut,
}

export enum UserConsentEmitter {
  Request = 'request-consent',
  Show = 'show-consent',
  Hide = 'hide-consent',
  Denied = 'consent-denied',
  Granted = 'consent-granted',
}

export const UserConsentConstants = {
  userOptedIn: 'userOptedIn',
  watcherKey: '$store.state.userConsentOptState',
  storageKey: 'userConsentOptState',
  commitAction: 'setUserConsentOptState',
  googleMapsLoaded: 'googleMapsLoaded',
}
