
import { Vue, Component } from 'vue-property-decorator'
import ResultNotFound from './ResultNotFound.vue'

@Component({
  components: {
    ResultNotFound,
  },
})
export default class ResultNotFoundSlide extends Vue {}
