import { Component, Vue } from 'vue-property-decorator'
import { baseUrlCreator } from '@/helpers/baseUrlCreator'
import Vuex from 'vuex'
Vue.use(Vuex)
const baseUrl = baseUrlCreator()

@Component
export class BankUrlRequest extends Vue {
  async get(): Promise<string | null> {
    const params = {
      _limit: 1,
      _fields: ['institute.url'],
      bank_code: this.$store.state.clientInformation.bankCode,
    }

    const response = await this.$http.get(baseUrl + '/locations', {
      headers: {
        token: this.$store.state.clientInformation.apiToken,
      },
      params,
    })

    try {
      return response.data.data[0].institute.url
    } catch (e) {
      return null
    }
  }
}
