
import { Vue, Component, Watch } from 'vue-property-decorator'
import UserConsentWrapper from '@/components/userConsent/Wrapper.vue'
import { trackerLoader } from '@/helpers/Trackers'
import { templateTheme } from '@/helpers/TemplateTheme'
import 'ie11-custom-properties'

@Component({
  components: { UserConsentWrapper },
})
export default class App extends Vue {
  userConsentStateChange(evt: string) {
    // console.log('App: UserConsentStateChange: ', evt)
  }

  mounted(): void {
    this.setupView()
  }

  setupView() {
    this.addTracker()
  }

  @Watch('$store.state.clientInformation.allowedLocationSearchDomains')
  checkAllowedDomains() {
    const allowedDomains =
      this.$store.state.clientInformation.allowedLocationSearchDomains

    const referrer = document.referrer
    let allowed = false

    if (allowedDomains.length == 0) return

    if (referrer) {
      const referrerUrl = new URL(referrer).hostname
      allowedDomains.forEach((allowedDomain: string) => {
        if (referrerUrl == allowedDomain) allowed = true
      })
    }

    if (!allowed) document.location.href = 'about:blank'
  }

  @Watch('$store.state.clientInformation.tracker')
  addTracker() {
    trackerLoader(this.$store.state.clientInformation.tracker)
  }

  @Watch('$store.state.clientInformation.template')
  setTemplateTheme() {
    templateTheme(this.$store.state.clientInformation.template)
  }
}
