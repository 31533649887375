
//
// adopted by https://github.com/maoberlehner/transition-to-height-auto-with-vue
//
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'TransitionExpand',
})
export default class TransitionExpand extends Vue {
  @Prop() expand: any

  afterEnter(element: HTMLElement) {
    element.style.height = 'auto'
  }

  enter(element: HTMLElement) {
    const { width } = getComputedStyle(element)

    element.style.width = width
    element.style.position = 'absolute'
    element.style.visibility = 'hidden'
    element.style.height = 'auto'

    const { height } = getComputedStyle(element)

    element.style.width = ''
    element.style.position = ''
    element.style.visibility = ''
    element.style.height = '0'

    // Force repaint to make sure the
    // animation is triggered correctly.
    getComputedStyle(element).height

    setTimeout(() => {
      element.style.height = height
    })
  }

  leave(element: HTMLElement) {
    const { height } = getComputedStyle(element)

    element.style.height = height

    // Force repaint to make sure the
    // animation is triggered correctly.
    getComputedStyle(element).height

    setTimeout(() => {
      element.style.height = '0'
    })
  }
}
