
import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import vueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

import MinusSvg from '@/assets/icons/minus.svg'
import PlusSvg from '@/assets/icons/plus.svg'

@Component({
  components: {
    vueSlider,
    MinusSvg,
    PlusSvg,
  },
})
export class ZoomLevelSlider extends Vue {
  minValue: number = 3
  maxValue: number = 21

  options: any = {
    direction: 'btt',
    width: 2,
    dotSize: 16,
    min: this.minValue,
    max: this.maxValue,
    interval: 1.0,
    tooltip: 'none',
    clickable: true,
    useKeyboard: true,
    dotStyle: {
      borderWidth: '1px',
      borderColor: '#fff',
      borderStyle: 'solid',
    },
  }

  decrementValue(): void {
    if (this.$store.state.google.zoomLevel > this.minValue)
      this.$store.commit(
        'setGoogleZoomLevel',
        this.$store.state.google.zoomLevel - 1
      )
  }

  incrementValue(): void {
    if (this.$store.state.google.zoomLevel < this.maxValue)
      this.$store.commit(
        'setGoogleZoomLevel',
        this.$store.state.google.zoomLevel + 1
      )
  }

  applySliderValueChange(sliderValue: number): void {
    if (sliderValue < this.maxValue && sliderValue > this.minValue)
      this.$store.commit('setGoogleZoomLevel', sliderValue)
  }
}

export default ZoomLevelSlider
