import '@babel/polyfill'

import '@/assets/markers.scss'
import { intersection } from 'lodash'
import Vue from 'vue'
import VueResource from 'vue-resource'
import App from './App.vue'
import { allKinds, availableFilters } from './mixins/apiContentDecorator'
import router from './router'
import store from './store'

const hacks = require('viewport-units-buggyfill/viewport-units-buggyfill.hacks.js')
require('viewport-units-buggyfill').init({
  hacks: hacks,
})

if (navigator.userAgent.match(/Googlebot/)) throw 'no bots'

Vue.use(VueResource)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeDestroy() {
    window.removeEventListener('resize', this.setClientWidth)
  },
  created() {
    this.parseParams()
    this.setClientWidth()
    window.addEventListener('resize', this.setClientWidth)
  },
  methods: {
    setClientWidth() {
      this.$store.commit('setClientWidth', document.body.clientWidth)
    },
    parseParams(): void {
      this.parseApiToken()
      if (!this.$store.state.clientInformation.apiToken) return

      this.$store.dispatch('getClientConfiguration')

      this.cityParamHack()
      this.parseSkipLocationDetection()
      this.parseAddress()
      this.parseSkipInitialModal()
      this.parseKindOptions()
      this.parsePreferredBankCode()
      this.parseDeeplinkParams()
      this.parseFilters()
      // parseAutofit will use any previously defined filters, make sure it
      // gets called at the end
      this.parseAutofit()
      this.parseBankCode()
      this.parseSkipConsent()

      this.requestBankPrivacyUrl()
    },

    parseSkipConsent() {
      if (!this.$route.query['config.skip_consent']) return

      if (this.$route.query['config.skip_consent'] == 'true') {
        this.$store.commit('setClientInformation', {
          name: 'consentSkipped',
          value: true,
        })
      }
    },
    parseApiToken() {
      if (!this.$route.query['config.api_token']) return
      this.$store.commit('setClientInformation', {
        name: 'apiToken',
        value: this.$route.query['config.api_token'],
      })
    },

    // Ticket #87329 _Einbindung in neue webBank-Startseite_
    // this client has no means (...) to build a url with the right parameters,
    // at least not for now, the only thing that they can do is to configure
    // a fixed url for their whole system and append a `&city=Bonn` (or other city)
    // at the end it.
    //
    // TODO: This is supposed to be a temporary solution until they manage
    // to configure their frames properly. Remove it as soon as possible.
    cityParamHack() {
      if (!this.$route.query['city']) return

      this.$route.query['config.address'] = this.$route.query['city']
      this.$store.commit('setClientInformation', {
        name: 'renderInitialPanel',
        value: false,
      })
    },

    parseAddress() {
      if (!this.$route.query['config.address']) return

      // second condition here is part of the cityParamHack and
      // you might want to remove it with the cityParamHack
      if (this.$route.query['config.autofit'] && !this.$route.query['city']) {
        console.warn(
          'the address parameter is being ignored due to the autofit option'
        )
        if (!this.$store.state.clientInformation.skipLocationDetection) return
      }

      this.$store.commit('setClientInformation', {
        name: 'skipLocationDetection',
        value: true,
      })
      this.$store.commit('setClientInformation', {
        name: 'requestedAddress',
        value: this.$route.query['config.address'],
      })
    },

    parseSkipLocationDetection() {
      if (!this.$route.query['config.skip_location_detection']) return
      this.$store.commit('setClientInformation', {
        name: 'skipLocationDetection',
        value: true,
      })
    },

    parseSkipInitialModal() {
      if (!this.$route.query['config.skip_initial_modal']) return
      this.$store.commit('setClientInformation', {
        name: 'renderInitialPanel',
        value: false,
      })
    },

    parseKindOptions() {
      if (
        !this.$route.query['config.kind_options'] ||
        !Array.isArray(this.$route.query['config.kind_options'])
      )
        return

      const kinds = this.$route.query['config.kind_options'].filter(
        (value: string) => allKinds.includes(value)
      )
      this.$store.commit('setClientInformation', {
        name: 'kindOptions',
        value: kinds,
      })
      this.$store.commit('setFilterValueByName', {
        name: 'kind',
        value: intersection(kinds, this.$store.state.filters.kind),
      })
    },

    parsePreferredBankCode() {
      if (!this.$route.query['config.preferred_bank_code']) return
      this.$store.commit('setClientInformation', {
        name: 'preferredBankCode',
        value: this.$route.query['config.preferred_bank_code'],
      })
    },

    parseBankCode() {
      if (!this.$route.query['config.bank_code']) return
      this.$store.commit('setClientInformation', {
        name: 'bankCode',
        value: this.$route.query['config.bank_code'],
      })
    },

    async requestBankPrivacyUrl() {
      if (this.$route.query['config.bank_code'])
        this.$store.dispatch('getBankUrl')
    },

    parseDeeplinkParams() {
      this.$store.commit('setClientInformation', {
        name: 'deeplinkId',
        value: this.$route.query['config.deeplink_id'],
      })
      this.$store.commit('setClientInformation', {
        name: 'deeplinkTag',
        value: this.$route.query['config.deeplink_tag'],
      })
    },

    parseFilters() {
      availableFilters.forEach((key) => {
        let value = this.$route.query['filters.' + key]

        if (value)
          this.$store.commit('setFilterValueByName', {
            name: key,
            value: value,
          })
      })

      // dynamic attributes are not whitelisted, we add every filters.dynamic_attributes.name
      // that is present in the query
      Object.keys(this.$route.query).forEach((key) => {
        const value = this.$route.query[key]

        if (key.startsWith('filters.dynamic_attributes.') && value) {
          this.$store.commit('setFilterValueByName', {
            name: key.replace(/^filters\./, ''),
            value: value,
          })
        }
      })
    },

    parseAutofit() {
      // second condition here is part of the cityParamHack and
      // you might want to remove it with the cityParamHack
      if (!this.$route.query['config.autofit'] || this.$route.query['city'])
        return

      this.$store.commit('setClientInformation', {
        name: 'skipLocationDetection',
        value: true,
      })
      this.$store.commit('setClientInformation', {
        name: 'autofit',
        value: true,
      })
      this.$store.dispatch('getPlacesBounds')
    },
  },
}).$mount('#app')
