import { Component, Vue } from 'vue-property-decorator'
import { PlacesBoundsRequestParams } from '@/types/api'
import { Bounds } from '@/types/marker'
import { baseUrlCreator } from '@/helpers/baseUrlCreator'
import Vuex from 'vuex'
Vue.use(Vuex)
const baseUrl = baseUrlCreator()

@Component
export class PlacesBoundsRequest extends Vue {
  async get(params: PlacesBoundsRequestParams): Promise<Bounds> {
    const response = await this.$http.get(baseUrl + '/placesBounds', {
      headers: {
        token: this.$store.state.clientInformation.apiToken,
      },
      params,
    })

    return this.dataToBounds(response.data)
  }

  dataToBounds(data: any) {
    if (data === {} || data === '') return

    const bs = data.bounds

    return <Bounds>{
      southWest: { latitude: bs.bottom_right.lat, longitude: bs.top_left.lon },
      northEast: { latitude: bs.top_left.lat, longitude: bs.bottom_right.lon },
    }
  }
}
