
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator'

// import svg icons
import BankSvg from '@/assets/icons/vr-logo.svg'
import BankSvg__Psd from '@/assets/icons/bank-psd__psd.svg'
import UncheckedBankSvg from '@/assets/icons/vr-logo-grey.svg'
import UncheckedBankSvg__Psd from '@/assets/icons/bank-grey__psd.svg'
import AtmSvg from '@/assets/icons/geldautomat.svg'
import AtmSvg__Psd from '@/assets/icons/geldautomat__psd.svg'
import UncheckedAtmSvg from '@/assets/icons/geldautomat-grey.svg'
import UncheckedAtmSvg__Psd from '@/assets/icons/geldautomat-grey.svg'
import ShoppingCartSvg from '@/assets/icons/shopping-cart.svg'
import ShoppingCartSvg__Psd from '@/assets/icons/shopping-cart__psd.svg'
import UncheckedShoppingCartSvg from '@/assets/icons/shopping-cart-grey.svg'
import UncheckedShoppingCartSvg__Psd from '@/assets/icons/shopping-cart-grey.svg'

import { kindMapper } from '@/mixins/apiContentDecorator'

@Component({
  components: {
    BankSvg,
    BankSvg__Psd,
    UncheckedBankSvg,
    UncheckedBankSvg__Psd,
    ShoppingCartSvg,
    ShoppingCartSvg__Psd,
    UncheckedShoppingCartSvg,
    UncheckedShoppingCartSvg__Psd,
    AtmSvg,
    AtmSvg__Psd,
    UncheckedAtmSvg,
    UncheckedAtmSvg__Psd,
  },
})
export default class TypeChooser extends Vue {
  private kindMapper = kindMapper

  @Prop() selected: Array<string>

  @Emit('value-change')
  toggleType(type: string) {
    let return_value = [...this.selected]
    if (!this.typeChecked(type)) {
      return_value.push(type)
    } else {
      return_value = return_value.filter((element: string) => element !== type)
    }
    return return_value
  }

  typeChecked(type: string): boolean {
    return this.selected.includes(type)
  }

  estimateComponent(type: string, theme: string): string {
    let logo
    switch (type) {
      case 'bank':
        logo = 'BankSvg'
        break

      case 'atm':
        logo = 'AtmSvg'
        break

      case 'cashback':
        logo = 'ShoppingCartSvg'
        break
    }
    if (!this.typeChecked(type)) logo = 'Unchecked' + logo

    if (theme == 'psd') return logo + '__Psd'

    return logo
  }
}
