export function templateTheme(template: string) {
  switch (template) {
    case 'psd':
      loadPsdTheme()
      break
  }
}

function loadPsdTheme() {
  document.documentElement.style.setProperty('--color-primary', '#009966')
  document.documentElement.style.setProperty('--color-secondary', '#e6a200')
  document.documentElement.style.setProperty('--color-text-main', '#646464')
}
