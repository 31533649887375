declare const _etracker: any
declare const et_UserDefinedEvent: any
import { WindowFix } from '@/shims/window'

const category = 'standortsuche'

function newDefer() {
  const deferred: any = {
    promise: null,
    resolve: null,
    reject: null,
  }

  deferred.promise = new Promise((resolve, reject) => {
    deferred.resolve = resolve
    deferred.reject = reject
  })

  return deferred
}

if (WindowFix.parent !== WindowFix.self) {
  window.addEventListener('message', (event) => {
    console.log('consent: message', event)
    try {
      const data = JSON.parse(event.data)
      // if value contains "2", the tracking for statistical reason is allowed
      if (
        data.type === 'consent' &&
        data.params &&
        typeof data.params.cookieValue === 'string'
      ) {
        if (data.params.cookieValue.match(/2/)) {
          console.log('consent: tracking enabled') // remove debug on production
          WindowFix.trackerConsent = true
          WindowFix.tracker.load()
        } else {
          WindowFix.trackerConsent = false
          console.log('consent: tracking disabled') // remove debug on production
        }
      }
    } catch (e) {}
  })
}

export function trackerLoader(trackerType: string) {
  WindowFix.trackerConsent = false

  switch (trackerType) {
    case 'vr':
      WindowFix.tracker = new VrTracker()
      break
    case 'bayern-vr':
      WindowFix.tracker = new BayernVrTracker()
      break
    case 'bwgv-vr':
      WindowFix.tracker = new BwgvVrTracker()
      break
    case 'mv-vr':
      WindowFix.tracker = new MvVrTracker()
      break
    case 'sh-vr':
      WindowFix.tracker = new ShVrTracker()
      break
    case 'weser-ems-vr':
      WindowFix.tracker = new WeserEmsVrTracker()
      break
    case 'immobilien-vr':
      WindowFix.tracker = new ImmobilienVrTracker()
      break
    default:
      WindowFix.tracker = new Tracker()
  }

  getParentConsent()
}

function getParentConsent() {
  if (window.parent !== window.self) {
    window.parent.postMessage({ type: 'getConsent' }, '*')
    console.log('getConsent triggered') // remove debug on production
  }
}

export class Tracker {
  trackerReady: boolean = false
  deferreds: Array<any> = []

  debug(value: string): void {
    if (process.env.VUE_APP_DEBUG != 'true') return
    console.info('Tracker', value)
  }

  load() {
    this.onLoad()
  }

  onLoad() {
    this.trackerReady = true
    this.runDeferreds()
  }

  whenTrackerReady() {
    if (this.trackerReady) return Promise.resolve(true)

    const deferred = newDefer()
    this.deferreds.push(deferred)
    return deferred.promise
  }

  runDeferreds() {
    this.deferreds.forEach((deferred) => deferred.resolve())
  }

  track(key: string, data?: any): void {
    // refresh consent status on track
    getParentConsent()

    // only track things if parent consent is given / allowed
    if (!WindowFix.trackerConsent) {
      return
    }

    this.debug(key)

    switch (key) {
      case 'phoneLinkClick':
        return this.phoneLinkClick(data)
      case 'addressInputSearch':
        return this.addressInputSearch(data)
      case 'showAllOpeningTimes':
        return this.showAllOpeningTimes(data)
      case 'showAllServices':
        return this.showAllServices(data)
      case 'mailLinkClick':
        return this.mailLinkClick(data)
      case 'formLinkClick':
        return this.formLinkClick(data)
      case 'navigationLinkClick':
        return this.navigationLinkClick(data)
      case 'callToActionLinkClick':
        return this.callToActionLinkClick(data)
      case 'detailsLinkClick':
        return this.detailsLinkClick(data)
      case 'showMoreToggle':
        return this.detailsLinkClick(data)
      default:
        console.warn('event not defined', key)
    }
  }

  phoneLinkClick(data?: any): void {}
  addressInputSearch(data?: any): void {}
  showAllOpeningTimes(data?: any): void {}
  showAllServices(data?: any): void {}
  mailLinkClick(data?: any): void {}
  navigationLinkClick(data?: any): void {}
  formLinkClick(data?: any): void {}
  callToActionLinkClick(data?: any): void {}
  detailsLinkClick(data?: any): void {}
  showMoreToggle(data?: any): void {}
}

export class VrTracker extends Tracker {
  secureCode: string = '9J34VK'

  load() {
    WindowFix.onTrackerLoad = () => {
      this.onLoad()
    }
    //   <script type="text/javascript">
    //   //var et_pagename = "";
    //   //var et_areas = "";
    //   //var et_target = "";
    //   //var et_tval = "";
    //   //var et_tonr = "";
    //   //var et_tsale = 0;
    //   //var et_basket = "";
    //   //var et_cust = 0;
    // </script>
    // <script id="_etLoader" type="text/javascript" charset="UTF-8" data-respect-dnt="true" data-secure-code="OBV0Ls"
    //   src="//static.etracker.com/code/e.js"></script>
    // <!-- etracker tracklet 4.1 end -->
    const trackerScript = document.createElement('script')
    trackerScript.setAttribute('onload', 'window.onTrackerLoad()')
    trackerScript.setAttribute('src', '//static.etracker.com/code/e.js')
    trackerScript.setAttribute('id', '_etLoader')
    trackerScript.setAttribute('charset', 'UTF-8')
    trackerScript.setAttribute('data-respect-dnt', 'true')
    trackerScript.setAttribute('data-secure-code', this.secureCode)
    document.head.appendChild(trackerScript)
  }

  sendEvent(...args: any) {
    this.whenTrackerReady().then(() => {
      _etracker.sendEvent(
        // new et_UserDefinedEvent('myObject', 'myCategory', 'myAction', 'myType'))
        new et_UserDefinedEvent(...args)
      )
    })
  }

  phoneLinkClick(data: any = {}) {
    this.sendEvent('lead-standortsuche-call', category, data.name, data.kind)
  }

  addressInputSearch(data: any = {}) {
    this.sendEvent('lead-standortsuche-search', category, data.name, data.kind)
  }

  showAllOpeningTimes(data: any = {}) {
    this.sendEvent(
      'lead-standortsuche-opening-times',
      category,
      data.name,
      data.kind
    )
  }

  showAllServices(data: any = {}) {
    this.sendEvent(
      'lead-standortsuche-services',
      category,
      data.name,
      data.kind
    )
  }

  mailLinkClick(data: any = {}) {
    this.sendEvent('lead-standortsuche-mail', category, data.name, data.kind)
  }

  navigationLinkClick(data: any = {}) {
    this.sendEvent('lead-standortsuche-route', category, data.name, data.kind)
  }

  formLinkClick(data: any = {}) {
    this.sendEvent('lead-standortsuche-contact', category, data.name, data.kind)
  }

  callToActionLinkClick(data: any = {}) {
    this.sendEvent(
      'lead-standortsuche-call-to-action',
      category,
      data.name,
      data.kind
    )
  }

  detailsLinkClick(data: any = {}) {
    this.sendEvent('lead-standortsuche-details', category, data.name, data.kind)
  }

  showMoreToggle(data: any = {}) {
    this.sendEvent(
      'lead-standortsuche-more-information',
      category,
      data.name,
      data.kind
    )
  }
}

export class BayernVrTracker extends VrTracker {
  secureCode: string = 'e8KOSE'
}

export class BwgvVrTracker extends VrTracker {
  secureCode: string = 'e8KOnx'
}

export class MvVrTracker extends VrTracker {
  secureCode: string = 'e8KP9g'
}

export class ShVrTracker extends VrTracker {
  secureCode: string = 'e8Khj9'
}

export class WeserEmsVrTracker extends VrTracker {
  secureCode: string = 'ed9Wj9'
}

export class ImmobilienVrTracker extends VrTracker {
  secureCode: string = '9J349b'
}
