import { Component, Vue } from 'vue-property-decorator'
import { PlacesRequestParams, PlaceResult } from '@/types/api'
import { baseUrlCreator } from '@/helpers/baseUrlCreator'
import Vuex from 'vuex'
import { LatitudeLongitude } from '@/types/marker'
Vue.use(Vuex)
const baseUrl = baseUrlCreator()

@Component
export class PlacesRequest extends Vue {
  async get(params: PlacesRequestParams): Promise<Array<PlaceResult>> {
    const response = await this.$http.get(baseUrl + '/places', {
      headers: {
        token: this.$store.state.clientInformation.apiToken,
      },
      params,
    })

    try {
      const center: LatitudeLongitude = {
        latitude: response.data.meta.search_center_latitude,
        longitude: response.data.meta.search_center_longitude,
      }
      await this.$store.commit('setPosition', center)
    } catch (e) {
      console.error(response.data, e)
    }

    return response.data.data || []
  }
}
