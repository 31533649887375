
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator'
import GoogleFormHeader from './GoogleFormHeader.vue' // @ is an alias to /src
import FormFilters from './FormFilters.vue' // @ is an alias to /src
import ResultList from './results/ElementList.vue' // @ is an alias to /src
import ResultNotFound from './results/ResultNotFound.vue' // @ is an alias to /src

import { GoogleLatLng } from '@/types/google'
import FormHeader from '@/components/sidebar/FormHeader.vue'

@Component({
  components: {
    FormHeader,
    GoogleFormHeader,
    FormFilters,
    ResultList,
    ResultNotFound,
  },
})
export default class Panel extends Vue {
  @Prop() results: any
  @Prop() useGoogleMaps: boolean

  showFormFilters: boolean = false

  mobileBreakpoint: number = 600

  @Prop() initial: boolean
  @Prop() animationMoveSuccess: boolean
  @Prop() displayToggle: boolean

  get formHeaderComponent() {
    return this.useGoogleMaps ? 'GoogleFormHeader' : 'FormHeader'
  }

  mounted(): void {
    this.$store.commit('setPlacesRequestMode', 'sidebar')
  }

  @Emit()
  changeLocationToResult(result: any): void {
    return result
  }

  @Emit()
  changeLocationByPosition(value: GoogleLatLng): GoogleLatLng {
    return value
  }

  @Emit()
  changeLocation(value: string): string {
    return value
  }
}
