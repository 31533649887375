import { Component, Prop, Vue } from 'vue-property-decorator'
import { MarkerElement } from '@/types/marker'
import { GoogleLatLng, MapMarkerOverlayOptions } from '@/types/google'
import MapMarkerOverlay from '@/mixins/googleMaps/MapMarkerOverlay'

@Component
class MapMarker extends Vue implements MarkerElement {
  @Prop() latitude: number
  @Prop() longitude: number
  @Prop() map?: any
  @Prop() mainId: string
  @Prop() ids: Array<string>

  overlay: any
  markerCssClass: string

  onClick: VoidFunction

  mounted(): void {
    this.protectedMount()
  }

  render() {}

  protected protectedMount(): void {
    this.overlay = new MapMarkerOverlay(
      this.mapMarkerOverlayOptions(),
      this.map
    )
  }

  beforeDestroy(): void {
    this.protectedBeforeDestroy()
  }

  protected protectedBeforeDestroy(): void {
    this.overlay.remove()
  }

  protected mapMarkerOverlayOptions(): MapMarkerOverlayOptions {
    return <MapMarkerOverlayOptions>{
      position: <GoogleLatLng>{ lat: this.latitude, lng: this.longitude },
      markerCssClass: this.markerCssClass,
      onClick: this.onClick.bind(this),
    }
  }
}

export { MapMarker }
export default MapMarker
