
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Element from './Element.vue'
import { Debounce, Bind } from 'lodash-decorators'

@Component({
  components: {
    Element,
  },
})
export default class ElementList extends Vue {
  @Prop() results: Array<any>
  focusOnElementRequired: boolean = false

  @Watch('$store.state.selectedElement')
  selectedElementChange(): void {
    this.focusOnElementRequired = true
  }

  mounted(): void {}

  updated(): void {
    this.focusOnElementIfNeeded()
  }

  focusOnElementIfNeeded(): void {
    if (!this.focusOnElementRequired || !this.$store.state.selectedElement)
      return

    this.focusOnElementRequired = false

    const elementRef: any = this.$refs[this.$store.state.selectedElement.id]
    if (!elementRef || !elementRef[0]) return

    const selectedElement: HTMLElement = elementRef[0].$el
    const selectedElementTop: number =
      selectedElement.getBoundingClientRect().top
    const selectedElementTopVisible: boolean =
      selectedElementTop >= this.$el.getBoundingClientRect().top &&
      selectedElementTop <= this.$el.getBoundingClientRect().bottom

    if (selectedElementTopVisible) return

    if (process.env.VUE_APP_DEBUG === 'true') console.log('focus on selected')
    this.$el.scrollTo(0, 0)
  }

  changeLocationToResult(result: any) {
    this.$emit('changeLocationToResult', result)
  }

  @Debounce(100)
  @Bind
  scrollHandler(event: any) {
    let bottomElementInSight: boolean =
      event.target.lastChild.getBoundingClientRect().top <
      event.target.getBoundingClientRect().bottom

    if (bottomElementInSight) this.$store.dispatch('increasePlacesList')
  }
}
