
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { MapMarker } from '@/mixins/googleMaps/MapMarker'
import markerComponents from './markerImportHelper'
import { MarkerElement } from '@/types/marker'
import { PlacesClusterResult } from '@/types/api'
declare const google: any

@Component({
  mixins: [markerComponents],
})
export default class Cluster extends Vue {
  @Prop() map: any

  markerType(marker: PlacesClusterResult): string {
    if (this.map.getZoom() < 12 && marker.count > 1) return 'ClusterMarker'
    if (marker.kinds.includes('bank')) return 'BankMarker'
    if (marker.kinds.includes('atm')) return 'AtmMarker'
    if (marker.kinds.includes('cashback')) return 'CashbackMarker'
  }

  formatData(marker: PlacesClusterResult): MarkerElement {
    return <MarkerElement>{
      latitude: marker.lat,
      longitude: marker.lon,
      mainId: this.mainId(marker.ids),
      ids: marker.ids,
      preferred: this.preferred(marker.count, marker.bank_codes),
      cluster: marker.count > 1,
      count: marker.count,
      mainSubtype: this.mainSubtype(marker.subtypes),
    }
  }

  mainId(ids: Array<string>): string {
    return (
      ids.find(function (id) {
        return id.includes('bank')
      }) ||
      ids.find(function (id) {
        return id.includes('atm')
      }) ||
      ids[0]
    )
  }

  viewKey(marker: PlacesClusterResult): string {
    const id = this.mainId(marker.ids)
    return `${id}-${marker.lat}-${marker.lon}`
  }

  mainSubtype(subtypes: Array<string> | null): string {
    if (subtypes === null) return null

    if (subtypes.includes('kc')) return 'kc'
    if (subtypes.includes('be')) return 'be'
    if (subtypes.includes('vr')) return 'vr'
    if (subtypes.includes('sparda')) return 'sparda'
    if (subtypes.includes('psd')) return 'psd'
    if (subtypes.includes('bbbank')) return 'bbbank'
    if (subtypes.includes('liga')) return 'liga'
    if (subtypes.includes('pax')) return 'pax'
    return 'vr'
  }

  preferred(count: number, bankCodes: Array<string>): boolean {
    return (
      count <= 10 &&
      (bankCodes || []).includes(
        this.$store.state.clientInformation.preferredBankCode
      )
    )
  }
}
