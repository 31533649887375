
import UserConsentModal from './Modal.vue'
import UserConsentToggle from './Toggle.vue'
import { Component, Emit, Vue, Watch } from 'vue-property-decorator'
import { UserConsentConstants, UserConsentOptState } from '@/types/user_consent'
import UserConsent from '@/mixins/UserConsent'

@Component({
  components: { UserConsentModal, UserConsentToggle },
  mixins: [UserConsent],
})
export default class UserConsentWrapper extends Vue {
  consentEnabled: boolean = false
  showModal: boolean = false
  initial: boolean = true

  mounted() {
    this.initial = this.$store.state.clientInformation.renderInitialPanel
  }

  @Watch('$store.state.clientInformation.consentEnabled')
  ready() {
    const newVal = this.$store.state.clientInformation.consentEnabled
    if (newVal == undefined) return

    this.consentEnabled = newVal
    if (this.consentEnabled) {
      this.notifyInitialState()
    } else {
      this.consentGranted()
    }
  }

  @Watch('$store.state.placesRequest.data')
  setInitial() {
    this.initial = false
  }

  @Emit()
  showConsentModal() {
    this.commitState(UserConsentOptState.None)
    this.showModal = true
  }

  @Emit()
  hideConsentModal() {
    this.showModal = false
  }

  @Emit()
  consentGranted() {
    this.commitState(UserConsentOptState.OptIn)
    this.hideConsentModal()
  }

  @Emit()
  consentDenied() {
    this.commitState(UserConsentOptState.OptOut)
    this.hideConsentModal()
  }

  commitState(state: UserConsentOptState) {
    this.$store.commit(UserConsentConstants.commitAction, state)
  }

  getState(): UserConsentOptState {
    return UserConsentOptState.None
  }

  notifyInitialState() {
    const state = this.getState()
    this.commitState(state)

    if (state !== UserConsentOptState.OptIn) {
      this.showConsentModal()
    }
  }

  @Watch('$store.state.userOptOut', { immediate: false })
  registerOptOut() {
    if (this.$store.state.userOptOut) {
      this.notifyInitialState()
    }
  }
}
