
import { Component, Vue, Watch } from 'vue-property-decorator'
@Component
export default class UserConsentModal extends Vue {
  privacy_url: string = '#'

  getUrlParams(search: string): any {
    let hashes = search.slice(search.indexOf('?') + 1).split('&')
    return hashes.reduce((params: any, hash: any) => {
      let [key, val] = hash.split('=')
      if (key != 'config.skip_consent' && key != 'config.address')
        return Object.assign(params, {
          [decodeURIComponent(key)]: decodeURIComponent(val),
        })
      else return params
    }, {})
  }

  resetByReloading() {
    const esc = encodeURIComponent
    const params = this.getUrlParams(window.location.search)
    if (this.$store.state.searchInput) {
      params['config.address'] = this.$store.state.searchInput
    }
    params['config.skip_consent'] = true

    const query = Object.keys(params)
      .map((k) => esc(k) + '=' + esc(params[k]))
      .join('&')

    document.location.href = document.location.href.replace(
      document.location.search,
      '?' + query
    )
  }

  @Watch('$store.state.clientInformation.bankUrl')
  updatePrivacyUrl() {
    if (this.$store.state.clientInformation.bankUrl) {
      const path =
        '/kontext?as_kontext=service/rechtliche-hinweise/datenschutzhinweis'
      this.privacy_url = `${this.$store.state.clientInformation.bankUrl}${path}`
    } else if (this.$store.state.clientInformation.privacyUrl) {
      this.privacy_url = this.$store.state.clientInformation.privacyUrl
    } else {
    }
  }

  mounted() {
    this.updatePrivacyUrl()
  }
}
