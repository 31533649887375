
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator'
import FormHeader from './FormHeader.vue' // @ is an alias to /src
import GoogleFormHeader from './GoogleFormHeader.vue' // @ is an alias to /src
import FormFilters from './FormFilters.vue' // @ is an alias to /src
import ResultSlider from './results/Slider.vue' // @ is an alias to /src

import WhiteCloseButton from '@/components/buttons/WhiteCloseButton.vue'
import TypeChooser from './formFilters/TypeChooser.vue'
import { GoogleLatLng } from '@/types/google'
import { LatitudeLongitude } from '@/types/marker'

@Component({
  components: {
    FormHeader,
    GoogleFormHeader,
    FormFilters,
    ResultSlider,
    TypeChooser,
    WhiteCloseButton,
  },
})
export default class InitialPanelContent extends Vue {
  @Prop() results: any
  @Prop() initial: boolean
  @Prop() animationMoveSuccess: boolean
  @Prop() displayToggle: boolean
  @Prop() mobileView: boolean
  @Prop() useGoogleMaps: boolean

  kind: Array<string> = []

  formHeaderComponent() {
    return this.useGoogleMaps ? 'GoogleFormHeader' : 'FormHeader'
  }

  mounted(): void {
    this.kind = this.$store.state.filters.kind
  }

  @Emit()
  changeLocationToResult(result: any): void {
    this.transform()
    return result
  }

  @Emit()
  changeLocationByPosition(value: GoogleLatLng): GoogleLatLng {
    this.transform()
    return value
  }

  isCoordinate(value: LatitudeLongitude | string): value is LatitudeLongitude {
    return (value as LatitudeLongitude).longitude !== undefined
  }

  isCenterLocation(lhs: LatitudeLongitude): boolean {
    const rhs: LatitudeLongitude = {
      latitude: this.$store.state.clientInformation.center.lat,
      longitude: this.$store.state.clientInformation.center.lng,
    }

    return lhs.latitude == rhs.latitude && lhs.longitude == rhs.longitude
  }

  @Watch('$store.state.position')
  changeLocation(
    value: LatitudeLongitude | string
  ): string | LatitudeLongitude {
    if (
      this.$store.state.position &&
      this.isCenterLocation(this.$store.state.position)
    )
      return
    if (this.isCoordinate(value)) {
      if (this.isCenterLocation(value)) return
    }

    this.$emit('change-location', value)
    // this.transform()
    return value
  }

  setKind(kind: Array<string>): void {
    this.kind = kind
    this.$store.commit('setFilterValueByName', {
      name: 'kind',
      value: this.kind,
    })
  }

  @Watch('$store.state.clientInformation.renderInitialPanel')
  @Emit()
  transform(): void {}

  @Emit()
  transformEmpty(): void {}
}
