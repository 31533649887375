import dayjs, { Dayjs } from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween' // load on demand
import { every, isEmpty, some } from 'lodash'

dayjs.extend(isBetween) // use plugin

export type OpeningHourPeriod = [string, string]
export type OpeningHourPeriods = Array<OpeningHourPeriod>
export interface OpeningHours {
  fri: OpeningHourPeriods
  mon: OpeningHourPeriods
  sat: OpeningHourPeriods
  sun: OpeningHourPeriods
  thu: OpeningHourPeriods
  tue: OpeningHourPeriods
  wed: OpeningHourPeriods
}

export type WeekDayString =
  | 'mon'
  | 'tue'
  | 'wed'
  | 'thu'
  | 'fri'
  | 'sat'
  | 'sun'
export type OpeningHoursWithDay = [WeekDayString, OpeningHourPeriods]
export type OpeningHourPeriodWithDay = [WeekDayString, OpeningHourPeriod]
export type SortedOpeningHoursWithDay = Array<OpeningHoursWithDay>

const weekDaysTranslations = {
  mon: 'Mo',
  tue: 'Di',
  wed: 'Mi',
  thu: 'Do',
  fri: 'Fr',
  sat: 'Sa',
  sun: 'So',
}

// hourString like "10:00"
// dateString like "2019-07-25" (YYYY-MM-DD)
export function hourAndDateStringToDateTime(
  hourString: string,
  dateString: string
): Dayjs {
  return dayjs(dateString + '  ' + hourString)
}

// It sorts the week days starting with one given day
// {
//    mon: ...,
//    tue: ...,
//    wed: ...,
//    thu: ...,
//    fri: ...,
//    sat: ...,
//    sun: ...
// }
// => sort for 'wed' =>
// [
//    ['wed', '...'],
//    ['thu', '...'],
//    ['fri', '...'],
//    ['sat', '...'],
//    ['sun', '...'],
//    ['mon', '...'],
//    ['tue', '...']
// ]
export function sortedOpeningHoursWithDayStartingWithOneGivenDay(
  openingHours: OpeningHours,
  day: WeekDayString
): SortedOpeningHoursWithDay {
  const weekDays: Array<WeekDayString> = [
    'mon',
    'tue',
    'wed',
    'thu',
    'fri',
    'sat',
    'sun',
  ]
  const sortedWeekDays: Array<WeekDayString> = weekDays
    .slice(weekDays.indexOf(day))
    .concat(weekDays.slice(0, weekDays.indexOf(day)))

  const result: SortedOpeningHoursWithDay = []

  sortedWeekDays.forEach((day) => {
    let dayValue: OpeningHourPeriods = openingHours[day]

    if (!isEmpty(dayValue)) result.push([day, dayValue])
  })

  return result
}

// {
//   fri: []
//   mon: [["05:00", "24:00"]]
//   sat: []
//   sun: []
//   thu: []
//   tue: [["10:00", "13:00"], ["14:00", "18:00"]]
//   wed: []
// }
// =>
// [
//   ['Mo', "05:00 - 24:00"],
//   ['Di', "10:00 - 13:00, 14:00 - 18:00"],
//   ['Mi', "geschlossen"],
//   ['Do', "geschlossen"],
//   ['Fr', "geschlossen"],
//   ['Sa', "geschlossen"],
//   ['So', "geschlossen"],
// ]
export function humanSortedOpeningHours(
  openingHours: OpeningHours
): Array<[string, string]> {
  return [
    ['Mo', combineOpeningHourPeriods(openingHours.mon)],
    ['Di', combineOpeningHourPeriods(openingHours.tue)],
    ['Mi', combineOpeningHourPeriods(openingHours.wed)],
    ['Do', combineOpeningHourPeriods(openingHours.thu)],
    ['Fr', combineOpeningHourPeriods(openingHours.fri)],
    ['Sa', combineOpeningHourPeriods(openingHours.sat)],
    ['So', combineOpeningHourPeriods(openingHours.sun)],
  ]
}

export function is24hPeriod(period: OpeningHourPeriod): boolean {
  if (!period) return false
  return (period[0] == '00:00' || period[0] == '0:00') && period[1] == '24:00'
}

export function isRoundTheClock(openingHours: OpeningHours): boolean {
  // make sure there is an entry for every day
  if (Object.keys(openingHours).length != 7) return false

  return every(Object.values(openingHours), (openingHourPeriods) =>
    is24hPeriod(openingHourPeriods[0])
  )
}

export function combineOpeningHourPeriods(
  openingHourPeriods: OpeningHourPeriods
): string {
  if (!openingHourPeriods || openingHourPeriods.length == 0)
    return 'geschlossen'
  if (is24hPeriod(openingHourPeriods[0])) return '24h geöffnet'

  return openingHourPeriods
    .map((element: OpeningHourPeriod) => element.join(' - '))
    .join(', ')
}

export function humanClosingInfo(
  openingHours: OpeningHours,
  timeObj: Dayjs
): string {
  const openingHourPeriodWithDay = currentOpeningHoursWithDay(
    openingHours,
    timeObj
  )
  if (!openingHourPeriodWithDay) return ''

  const [_, period] = openingHourPeriodWithDay

  if (period[1] == '24:00') return ''

  return 'schließt um ' + period[1]
}

export function humanNextOpeningInfo(
  openingHours: OpeningHours,
  timeObj: Dayjs
): string {
  const openingHourPeriodWithDay = nextOpeningHoursWithDay(
    openingHours,
    timeObj
  )
  if (isEmpty(openingHourPeriodWithDay)) return 'dauerhaft'

  const [day, period] = <[WeekDayString, OpeningHourPeriod]>(
    openingHourPeriodWithDay
  )
  const todayWeekday = timeObj.format('ddd')

  if (day.toLowerCase() === todayWeekday.toLowerCase())
    return `öffnet um ${period[0]}`

  return `öffnet ${weekDaysTranslations[day]} um ${period[0]}`
}

// timeObj is a dayjs or momentjs instance
// return ['mon', [10:00, 12:00]]
// return null
export function currentOpeningHoursWithDay(
  openingHours: OpeningHours,
  timeObj: Dayjs
): OpeningHourPeriodWithDay {
  if (!openingHoursAvailable(openingHours)) return null

  const weekDay = <WeekDayString>timeObj.format('ddd').toLowerCase() // mon, tue...
  const dateString = timeObj.format('YYYY-MM-DD')
  const dayPeriods: OpeningHourPeriods = <OpeningHourPeriods>(
    openingHours[weekDay]
  )

  if (isEmpty(dayPeriods)) return null

  const period = dayPeriods.find(function (p: OpeningHourPeriod) {
    let timeObjPeriod = openingHourPeriodToTimeObjPeriod(p, dateString)
    return timeObj.isBetween(timeObjPeriod[0], timeObjPeriod[1], null, '[)')
  })

  return period ? [weekDay, period] : null
}

// timeObj is a dayjs or momentjs instance
// return ['mon', [10:00, 12:00]]
// return null
export function nextOpeningHoursWithDay(
  openingHours: OpeningHours,
  timeObj: Dayjs
): OpeningHourPeriodWithDay {
  if (!openingHoursAvailable(openingHours)) return null

  const weekDay = <WeekDayString>timeObj.format('ddd').toLowerCase()
  const dateString = timeObj.format('YYYY-MM-DD')
  const sameDayPeriods: OpeningHourPeriods = openingHours[weekDay]

  if (!isEmpty(sameDayPeriods)) {
    const sameDayPeriod = sameDayPeriods.find(function (p) {
      let beginOfPeriod = hourAndDateStringToDateTime(p[0], dateString)
      return timeObj.isBefore(beginOfPeriod)
    })

    if (sameDayPeriod) return [weekDay, sameDayPeriod]
  }

  const nextWeekDay = <WeekDayString>(
    timeObj.add(1, 'day').format('ddd').toLowerCase()
  )
  const sortedPeriods = sortedOpeningHoursWithDayStartingWithOneGivenDay(
    openingHours,
    nextWeekDay
  )
  if (isEmpty(sortedPeriods)) return null

  const [nextDay, nextPeriods] = sortedPeriods.find(function ([_day, periods]) {
    return !isEmpty(periods)
  })
  return [nextDay, nextPeriods[0]]
}

// ['10:00', '12:00'], "2019-07-25"
// =>
// [datetime obj for 2019-07-25 at 10:00,  datetime obj for 2019-07-25 at 12:00]
export function openingHourPeriodToTimeObjPeriod(
  openingHourPeriod: OpeningHourPeriod,
  dateString: string
): [Dayjs, Dayjs] {
  return [
    hourAndDateStringToDateTime(openingHourPeriod[0], dateString),
    hourAndDateStringToDateTime(openingHourPeriod[1], dateString),
  ]
}

export function openingHoursAvailable(openingHours: OpeningHours): boolean {
  if (isEmpty(openingHours)) return false

  return some(openingHours, function (e) {
    return !isEmpty(e)
  })
}
