import { GoogleLatLng } from '@/types/google'
import { Position } from '@/types/navigator.geolocation'
import { Component, Vue } from 'vue-property-decorator'
import { findIndex } from 'lodash'
import { whenGoogleMapsReady } from '@/helpers/whenGoogleMap'

declare const google: any
declare const window: any

@Component
export default class Geolocation extends Vue {
  updateCurrentPosition(position: Position): void {
    let center: GoogleLatLng = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    }

    if (!window.googleMapsLoaded) {
      this.$store.dispatch('changePosition', position.coords)
    }

    whenGoogleMapsReady().then(() => {
      new google.maps.Geocoder().geocode(
        { latLng: center },
        (results: Array<any>, status: string) => {
          if (status == 'OK') {
            this.$store.commit('setSearchInput', results[0].formatted_address)
            this.$store.dispatch('changePosition', position.coords)
          } else {
            this.$store.commit('setPosition', position.coords)
          }
        }
      )
    })
  }

  findAddress(): void {
    whenGoogleMapsReady().then(() => {
      new google.maps.Geocoder().geocode(
        {
          address: this.$store.state.clientInformation.requestedAddress,
          componentRestrictions: { country: 'DE' },
        },
        (results: Array<any>, status: string) => {
          if (status != 'OK') {
            console.warn(`the google geocoder returned the status ${status}`)
            return
          }

          const firstLocalityIndex = findIndex(results, (e: any) =>
            e.types.includes('locality')
          )
          const result =
            firstLocalityIndex > -1 ? results[firstLocalityIndex] : results[0]
          this.parseGoogleGeocodeResult(result)
        }
      )
    })
  }

  parseGoogleGeocodeResult(result: any) {
    this.$store.commit('setClientInformation', {
      name: 'center',
      value: result.geometry.location,
    })
    this.$store.commit('setClientInformation', {
      name: 'bounds',
      value: [
        result.geometry.bounds.getNorthEast(),
        result.geometry.bounds.getSouthWest(),
      ],
    })
    const position = {
      latitude: result.geometry.location.lat(),
      longitude: result.geometry.location.lng(),
    }
    this.$store.commit('setPosition', position)
  }

  findCurrentPosition(err: any = null): void {
    navigator.geolocation.getCurrentPosition(this.updateCurrentPosition, err, {
      enableHighAccuracy: true,
      timeout: 500,
      maximumAge: 0,
    })
  }

  findCurrentPositionWithErrorInfo(): void {
    this.findCurrentPosition(this.handleError)
  }

  handleError(error: any): void {
    if (error.code == 1) {
      alert('Sie haben Ihren Standort nicht freigegeben.')
    }
  }
}
