import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { MapMarker } from '@/mixins/googleMaps/MapMarker'
import { GoogleLatLng, MapMarkerOverlayOptions } from '@/types/google'

@Component({
  name: 'LocationMarker',
})
export default class LocationMarker extends MapMarker {
  markerCssClass: string = 'map-marker-overlay--location'

  onClick = () => {
    // TODO center map to current position
  }

  protected mapMarkerOverlayOptions(): MapMarkerOverlayOptions {
    return <MapMarkerOverlayOptions>{
      position: <GoogleLatLng>{ lat: this.latitude, lng: this.longitude },
      markerCssClass: this.markerCssClass,
      onClick: this.onClick.bind(this),
      selected: true,
      preferred: false,
      cluster: false,
      small: true,
    }
  }
}
