import { Component, Prop, Watch } from 'vue-property-decorator'
import { MapMarker } from './MapMarker'
import { GoogleLatLng, MapMarkerOverlayOptions } from '@/types/google'
import { ActivatableMarkerElement } from '@/types/marker'
import MapMarkerOverlay from './MapMarkerOverlay'

@Component
class ActivatableMapMarker
  extends MapMarker
  implements ActivatableMarkerElement
{
  @Prop() preferred: boolean
  @Prop() cluster: boolean
  @Prop() mainSubtype: string

  selected: boolean

  onClick = function () {
    if (this.selected) return
    this.$store.dispatch('activateElementThroughMarker', this.$props)
  }

  @Watch('ids')
  @Watch('$store.state.selectedElement')
  selectedElementChange() {
    this.selected = this.selectedElementIncluded()
    this.overlay.update({ selected: this.selected })
  }

  selectedElementIncluded(): boolean {
    if (!this.$store.state.selectedElement) return false

    return this.ids.includes(this.$store.state.selectedElement.id)
  }

  protected protectedMount(): void {
    this.selected = this.selectedElementIncluded()
    this.overlay = new MapMarkerOverlay(
      this.mapMarkerOverlayOptions(),
      this.map
    )
  }

  protected mapMarkerOverlayOptions(): MapMarkerOverlayOptions {
    return <MapMarkerOverlayOptions>{
      position: <GoogleLatLng>{ lat: this.latitude, lng: this.longitude },
      markerCssClass: this.markerCssClass,
      onClick: this.onClick.bind(this),
      selected: this.selected,
      preferred: this.preferred,
      cluster: this.cluster,
    }
  }
}

export { ActivatableMapMarker }
export default ActivatableMapMarker
