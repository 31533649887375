
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator'
import SliderElement from './SliderElement.vue'
import PlusSvg from '@/assets/icons/plus.svg'
import { uniqBy } from 'lodash'

import 'swiper/dist/css/swiper.css'
// Import Swiper and modules
import Swiper from 'swiper/dist/js/swiper.js'
import { ZoomLevelSlider } from '@/components/ZoomLevelSlider.vue'
import { SelectedElement } from '@/store'
import { PlaceResult } from '@/types/api'
import ResultNotFoundSlide from './ResultNotFoundSlide.vue'

@Component({
  components: {
    SliderElement,
    PlusSvg,
    ResultNotFoundSlide,
  },
})
export default class Slider extends Vue {
  @Prop() results: Array<any>

  virtualData: any = { slides: [] }

  swiper: any

  mounted(): void {
    this.$set(this.virtualData, 'slides', [])
    const self = this
    this.swiper = new Swiper('.swiper-container', {
      centeredSlides: true,
      spaceBetween: 10,
      width: this.$store.state.clientInformation.width - 40,
      lazy: true,
      observer: true,
      // slidesPerView: 1,
      virtual: {
        cache: false,
        addSlidesBefore: 30,
        addSlidesAfter: 30,
        renderExternal(data: any) {
          // assign virtual slides data
          self.virtualData = data
        },
      },
    })

    this.swiper.on(
      'slideChangeTransitionEnd',
      this.selectElementFromCurrentSlide
    )
    this.swiper.on('reachEnd', this.addResults)
    // this.swiper.on('slideChange', this.addResults)
    this.updateVirtualSlides()
  }

  @Watch('results')
  updateVirtualSlides() {
    // length === 10 show that the result set changes
    // therefore remove all slides and add new ones avoiding slider change bug
    if (this.results.length === 10) {
      // @ts-ignore
      this.swiper.virtual.removeAllSlides()
      this.swiper.virtual.slides = [...this.results]
      this.swiper.virtual.update()
      this.swiper.slideTo(0, 0, false)
    } else {
      this.swiper.virtual.slides = uniqBy([...this.results], 'id')
      this.swiper.virtual.update()
    }
  }

  @Watch('$store.state.selectedElement')
  selectedElementChange() {
    if (this.slideToSelectedElement()) return
  }

  selectElementFromCurrentSlide(): void {
    const result: PlaceResult =
      this.swiper.virtual.slides[this.swiper.activeIndex]

    if (
      result === undefined ||
      result === null ||
      (this.$store.state.selectedElement &&
        this.$store.state.selectedElement.id === result.id)
    ) {
      return
    }
    this.$store.commit('setSelectedElement', <SelectedElement>{
      id: result.id,
      coords: {
        latitude: parseFloat(result.address.latitude),
        longitude: parseFloat(result.address.longitude),
      },
    })
  }

  addResults(): void {
    this.$store.dispatch('increasePlacesList')
  }

  // slides and returns true if the selected element is present in the slides,
  // false otherwise
  slideToSelectedElement(): boolean {
    if (!this.$store.state.selectedElement) return

    const sliderIds: Array<string> = this.virtualData.slides.map(
      (s: any) => s.id
    )
    const sliderIndex: number = sliderIds.indexOf(
      this.$store.state.selectedElement.id
    )

    if (sliderIndex > -1) {
      this.swiper.slideTo(sliderIndex, 50, false)
      return true
    }

    if (process.env.VUE_APP_DEBUG === 'true') console.log('Slide not found')
    this.$store.dispatch('initialPlacesLoad')
    return false
  }

  @Emit()
  changeLocationToResult(result: any): any {
    return result
  }
}
