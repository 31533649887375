import BbbankLogoSvg from '@/assets/icons/bank-bbbank.svg'
import BbbankLogoSvg__Psd from '@/assets/icons/bank-bbbank__psd.svg'
import LigaLogoSvg from '@/assets/icons/bank-liga.svg'
import LigaLogoSvg__Psd from '@/assets/icons/bank-liga__psd.svg'
import PaxLogoSvg from '@/assets/icons/bank-pax.svg'
import PaxLogoSvg__Psd from '@/assets/icons/bank-pax__psd.svg'
import BELogoSvg from '@/assets/icons/bank-be.svg'
import BELogoSvg__Psd from '@/assets/icons/bank-be.svg'
import KCLogoSvg from '@/assets/icons/bank-kc.svg'
import KCLogoSvg__Psd from '@/assets/icons/bank-kc.svg'
import PsdLogoSvg from '@/assets/icons/bank-psd.svg'
import PsdLogoSvg__Psd from '@/assets/icons/bank-psd__psd.svg'
import SpardaLogoSvg from '@/assets/icons/bank-sparda.svg'
import SpardaLogoSvg__Psd from '@/assets/icons/bank-sparda__psd.svg'
import DistanceSvg from '@/assets/icons/distance.svg'
import DistanceSvg__Psd from '@/assets/icons/distance__psd.svg'
import AtmSvg from '@/assets/icons/geldautomat.svg'
import AtmSvg__Psd from '@/assets/icons/geldautomat__psd.svg'
import ShoppingCartSvg from '@/assets/icons/shopping-cart.svg'
import ShoppingCartSvg__Psd from '@/assets/icons/shopping-cart__psd.svg'
import VrLogoSvg from '@/assets/icons/vr-logo.svg'
import VrLogoSvg__Psd from '@/assets/icons/vr-logo__psd.svg'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Content from '../Content.vue'

@Component({
  components: {
    DistanceSvg,
    DistanceSvg__Psd,
    Content,
    VrLogoSvg,
    VrLogoSvg__Psd,
    AtmSvg,
    AtmSvg__Psd,
    ShoppingCartSvg,
    ShoppingCartSvg__Psd,
    SpardaLogoSvg,
    SpardaLogoSvg__Psd,
    PsdLogoSvg,
    PsdLogoSvg__Psd,
    BbbankLogoSvg,
    BbbankLogoSvg__Psd,
    LigaLogoSvg,
    LigaLogoSvg__Psd,
    PaxLogoSvg,
    PaxLogoSvg__Psd,
    BELogoSvg,
    BELogoSvg__Psd,
    KCLogoSvg,
    KCLogoSvg__Psd,
  },
})
export default class ElementCommons extends Vue {
  @Prop() result: any
  showMore: boolean = false

  get formattedDistance(): string {
    if (this.result.address.distance < 1)
      return `${this.result.address.distance * 1000} m`
    else return `${this.result.address.distance} km`
  }

  estimateComponent(type: string, subtype: string, theme: string): string {
    const cmp = this.basicComponent(type, subtype)

    if (theme == 'psd') return cmp + '__Psd'

    return cmp
  }

  basicComponent(type: string, subtype: string) {
    switch (type) {
      case 'bank':
        switch (subtype) {
          case 'vr':
            return 'VrLogoSvg'
          case 'liga':
            return 'LigaLogoSvg'
          case 'pax':
            return 'PaxLogoSvg'
          case 'sparda':
            return 'SpardaLogoSvg'
          case 'psd':
            return 'PsdLogoSvg'
          case 'bbbank':
            return 'BbbankLogoSvg'
          case 'kc':
            return 'KCLogoSvg'
          case 'be':
            return 'BELogoSvg'
          default:
            return 'VrLogoSvg'
        }
      case 'atm':
        return 'AtmSvg'
      case 'cashback':
        return 'ShoppingCartSvg'
    }
  }
}
