
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator'
import LupeSvg from '@/assets/icons/lupe.svg'
import LocationSvg from '@/assets/icons/location.svg'
import CloseSvg from '@/assets/icons/close.svg'
import FilterSvg from '@/assets/icons/Filter.svg'
import MinusSvg from '@/assets/icons/minus.svg'
import Geolocation from '@/mixins/googleMaps/navigator.geolocation'
import { Debounce, Bind } from 'lodash-decorators'
import { WindowFix } from '@/shims/window'

declare const google: any

@Component({
  mixins: [Geolocation],
  components: {
    LupeSvg,
    LocationSvg,
    CloseSvg,
    FilterSvg,
    MinusSvg,
  },
})
export default class FormHeader extends Vue {
  selectedTypes: Array<string> = ['bank']
  @Prop() showOptionButton: boolean
  @Prop() initial: boolean
  @Prop() open: boolean

  center: any
  searchInput: HTMLInputElement = null
  searchTerms: string = ''
  focused: boolean = false
  places: any
  $store: any
  $route: any

  debounceFocusChange(focus: boolean) {
    setTimeout(() => (this.focused = focus), 150)
  }

  inputSubmit(e: KeyboardEvent) {
    if (e.code === 'Enter') {
      e.preventDefault()
      this.searchInput.blur()
      return this.inputChange()
    }
  }

  localizeMe() {
    this.clearInput()
    try {
      this.$store.commit('setSearchInput', null)
    } catch (e) {
      // wtf?!
    }
    ;(this as unknown as Geolocation).findCurrentPositionWithErrorInfo()
  }

  toggleInputFocus() {
    if (this.focused) {
      this.inputChange()
      this.searchInput.blur()
    } else this.searchInput.focus()
  }

  mounted(): void {
    this.searchTerms =
      this.$store.state.searchInput ||
      this.$store.state.clientInformation.requestedAddress ||
      this.$route.query['city'] ||
      ''

    this.searchInput = <HTMLInputElement>document.getElementById('gSearch')

    if (
      this.$store.state.clientInformation.requestedAddress ||
      this.$store.state.initialPanelUnUsed
    ) {
      this.inputChange()
    }
  }

  // @Watch('$store.state.placesRequest.data', { immediate: false })
  updateAddress() {
    try {
      const city = this.$store.state.placesRequest.data[0].address.city
      // this.searchTerms = this.searchTerms || city;
      // this.$store.commit('setSearchInput', city)
    } catch (e) {}
  }

  @Emit()
  toggleFilters(): void {}

  @Debounce(100)
  @Bind
  @Watch('$store.state.initialPanelUnUsed')
  inputChange(): void {
    WindowFix.tracker.track('addressInputSearch')

    if (this.searchTerms !== this.$store.state.searchInput)
      this.$store.commit('setSearchInput', this.searchTerms)

    this.$store.dispatch('updatePlaces')
    this.$emit('input-change', this.searchTerms)
  }

  clearInput() {
    this.searchTerms = ''
    this.searchInput.focus()
  }

  toggleType(type: string): void {
    if (this.selectedTypes.includes(type))
      this.selectedTypes = this.selectedTypes.filter((e) => e !== type)
    else this.selectedTypes.push(type)
  }
}
