
import { Vue, Component, Watch } from 'vue-property-decorator'
import Map from '@/components/googleMaps/Map.vue' // @ is an alias to /src™
import FakeMap from '@/components/FakeMap.vue' // @ is an alias to /src™
import Panel from '@/components/sidebar/Panel.vue'
import UserConsent from '@/mixins/UserConsent'
import MapsScript from '@/components/googleMaps/MapsScript.vue'
import Geolocation from '@/mixins/googleMaps/navigator.geolocation'

@Component({
  components: { MapsScript, FakeMap, Map, Panel },
  mixins: [UserConsent, Geolocation],
})
export default class Home extends Vue {
  showgMaps: boolean = false
  typeScriptFoo: unknown = this
  geoMixin: Geolocation = <Geolocation>this.typeScriptFoo

  @Watch('$store.state.clientInformation.consentSkipped')
  setShowGMaps() {
    this.showgMaps =
      this.$store.state.clientInformation.consentSkipped ||
      this.$store.state.userOptedIn
  }

  mounted() {
    if (!this.$store.state.clientInformation.skipLocationDetection) {
      this.geoMixin.findCurrentPosition()
    }
    this.setShowGMaps()
  }
}
