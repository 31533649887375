
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Cluster from './marker/Cluster.vue'
import markerComponents from './marker/markerImportHelper'
declare const google: any

import { PlacesClusterResult } from '@/types/api'
import { GoogleLatLng } from '@/types/google'
import {
  MarkerElementWithType,
  MarkerElement,
  LatitudeLongitude,
  Bounds,
  MapOffsets,
} from '@/types/marker'
import CurrentPosition from '@/mixins/googleMaps/navigator.geolocation'
import { Debounce, Bind } from 'lodash-decorators'

const navigationZoomBreakpoint: number = 15

const topbarOffsets: MapOffsets = {
  top: 75,
  bottom: 200,
  left: 0,
  right: 0,
}
const sidebarOffsets: MapOffsets = {
  top: 0,
  bottom: 0,
  left: 0, // dynamic
  right: 0,
}

@Component({
  mixins: [markerComponents, CurrentPosition],
  components: {
    Cluster,
  },
})
export default class Map extends Vue {
  map: any = null
  mapDragEvent: any
  currentPositionMarker: MarkerElementWithType = null
  furtherFocusOnSelectedElementRequired: boolean = false

  debug: { center: any; bounds: any; secondaryBounds: any } = {
    center: null,
    bounds: null,
    secondaryBounds: null,
  }

  @Debounce(400)
  @Bind
  onBoundsChanged() {
    if (this.$store.state.clientInformation.autofit) return
    if (!this.map || !this.map.getBounds()) return

    const googleBounds = this.visibleBounds()
    const bounds = <Bounds>{
      southWest: this.boundFormater(googleBounds.getSouthWest()),
      northEast: this.boundFormater(googleBounds.getNorthEast()),
    }

    this.$store.dispatch('boundsChanged', {
      bounds: bounds,
      zoomLevel: this.map.getZoom(),
      center: this.formattedGoogleMapCenter(),
    })
  }

  @Watch('$store.state.placesClustersRequest.data')
  @Debounce(400)
  ensureSelectedElementIsAvailable(): void {
    if (!this.furtherFocusOnSelectedElementRequired) return
    // we attempt to find it just once more
    this.furtherFocusOnSelectedElementRequired = false

    if (!this.$store.state.selectedElement || this.selectedElementAvailable)
      return

    this.navigateToSelected(18)
  }

  @Watch('$store.state.google.zoomLevel', { immediate: false })
  @Debounce(400)
  updateMapZoomLevel() {
    if (this.map == null) return

    if (this.$store.state.google.zoomLevel) {
      this.map.setZoom(this.$store.state.google.zoomLevel)
    }
  }

  @Watch('$store.state.google.center', { immediate: false })
  @Debounce(400)
  updateMapByStore() {
    if (this.map == null) return

    this.map.setCenter(
      new google.maps.LatLng(
        this.$store.state.google.center.latitude,
        this.$store.state.google.center.longitude
      )
    )
  }

  @Watch('$store.state.filters', { deep: true })
  onFiltersChange(): void {
    this.$store.dispatch('loadPlacesClusters')
  }

  @Watch('$store.state.selectedElement', { deep: true })
  selectedElementChange() {
    if (!this.$store.state.selectedElement) return

    this.furtherFocusOnSelectedElementRequired = true
    if (
      this.map.getZoom() < navigationZoomBreakpoint ||
      !this.selectedElementVisibleOnMap()
    ) {
      this.navigateToSelected()
    } else {
      this.ensureSelectedElementIsAvailable()
    }
  }

  @Watch('$store.state.position', { deep: true })
  updateCurrentPositionMarker(): void {
    this.currentPositionMarker = <MarkerElementWithType>{
      type: 'LocationMarker',
      data: <MarkerElement>this.$store.state.position,
    }
  }

  formattedGoogleMapCenter(): LatitudeLongitude {
    const center = this.map.getCenter()
    return {
      latitude: center.lat(),
      longitude: center.lng(),
    }
  }

  get selectedElementCoordinates(): GoogleLatLng {
    const googleLatLng: GoogleLatLng = new google.maps.LatLng(
      this.$store.state.selectedElement.coords.latitude,
      this.$store.state.selectedElement.coords.longitude
    )

    return googleLatLng
  }

  get selectedElementAvailable(): boolean {
    return this.$store.state.placesClustersRequest.data.some(
      (marker: PlacesClusterResult) =>
        marker.ids.includes(this.$store.state.selectedElement.id)
    )
  }

  get currentOffsets(): MapOffsets {
    if (this.$store.state.placesRequest.mode == 'topbar') return topbarOffsets
    else if (this.$store.state.placesRequest.mode == 'sidebar') {
      if (!document) {
        return <MapOffsets>{ top: 0, bottom: 0, left: 0, right: 0 }
      }
      const sidebar: HTMLElement = document.getElementById(
        'sidebar-panel-content'
      )
      sidebarOffsets.left = sidebar.clientWidth

      return sidebarOffsets
    } else return <MapOffsets>{ top: 0, bottom: 0, left: 0, right: 0 }
  }

  get containerHeight(): string {
    const offset: number = this.currentOffsets.bottom - this.currentOffsets.top
    const operation: string =
      offset < 0 ? `- ${Math.abs(offset)}` : `+ ${offset}`
    return `calc(100vh ${operation}px)`
  }

  get containerWidth(): string {
    const offset: number = this.currentOffsets.right - this.currentOffsets.left
    // const operation: string =
    //   offset < 0 ? `- ${Math.abs(offset)}` : `+ ${offset}`
    return `calc(100vw + ${Math.abs(offset)}px)`
  }

  get containerMarginTop(): string {
    const offset: number = this.currentOffsets.bottom - this.currentOffsets.top
    return `${-offset}px`
  }

  get containerMarginLeft(): string {
    const offset: number = this.currentOffsets.right - this.currentOffsets.left
    if (offset < 0) return '0px'
    else return `${offset}px`
  }

  mounted() {
    setTimeout(() => this.initMap(), 10)
  }

  @Watch('$store.state.clientInformation.bounds')
  fitBounds() {
    if (!this.$store.state.clientInformation.bounds) return
    this.$store.commit('setClientInformation', {
      name: 'autofit',
      value: false,
    })

    const bounds = new google.maps.LatLngBounds()
    this.$store.state.clientInformation.bounds.forEach(
      (element: GoogleLatLng) => {
        bounds.extend(element)
      }
    )
    this.debugModeForSecondaryBounds(bounds)

    // console.log('fitting bound')

    this.map.fitBounds(bounds, {
      top: this.currentOffsets.bottom,
      left: this.currentOffsets.left,
      right: this.currentOffsets.left,
      bottom: this.currentOffsets.bottom,
    })
    this.map.setCenter(bounds.getCenter())
  }

  @Watch('$store.state.clientInformation.center')
  fitCenter() {
    this.map.setCenter(this.$store.state.clientInformation.center)
    this.map.setZoom(15)
  }

  initMap(): void {
    this.map = new google.maps.Map(document.getElementById('map'), {
      zoom: this.$store.state.google.zoomLevel,
      fullscreenControl: false,
      mapTypeControl: false,
      zoomControl: false,
      streetViewControl: false,

      styles: [
        {
          featureType: 'transit',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'poi',
          stylers: [{ visibility: 'off' }],
        },
      ],
    })

    this.map.addListener('bounds_changed', this.onBoundsChanged)
    this.mapDragEvent = this.map.addListener('dragend', this.userInteraction)

    if (this.$store.state.clientInformation.bounds === null)
      this.map.setCenter(this.$store.state.clientInformation.center)
    else this.fitBounds()
  }

  userInteraction(): void {
    this.$store.commit('setClientInformation', {
      name: 'renderInitialPanel',
      value: false,
    })

    google.maps.event.removeListener(this.mapDragEvent)
  }

  visibleBounds(): any {
    const bounds = this.map.getBounds()

    const northeast: GoogleLatLng = this.applyOffsetToGivenPosition(
      bounds.getNorthEast(),
      this.currentOffsets.left,
      this.currentOffsets.bottom
    )

    const southwest: GoogleLatLng = this.applyOffsetToGivenPosition(
      bounds.getSouthWest(),
      -this.currentOffsets.left,
      -this.currentOffsets.bottom
    )

    const visibleBounds = new google.maps.LatLngBounds()
    visibleBounds.extend(northeast)
    visibleBounds.extend(southwest)

    this.debugModeForBounds(visibleBounds)

    return visibleBounds
  }

  beforeDestroy() {
    google.maps.event.clearInstanceListeners(this.map, 'bounds_changed')
  }

  boundFormater(bound: any): LatitudeLongitude {
    return { latitude: bound.lat(), longitude: bound.lng() }
  }

  selectedElementVisibleOnMap(): boolean {
    return this.visibleBounds().contains(this.selectedElementCoordinates)
  }

  applyOffsetToGivenPosition(
    position: GoogleLatLng,
    offsetX: number = 0,
    offsetY: number = 0
  ): GoogleLatLng {
    // estimate current point in map
    const point = this.map
      .getProjection()
      .fromLatLngToPoint(
        position instanceof google.maps.LatLng
          ? position
          : new google.maps.LatLng(position.lat, position.lng)
      )

    // estimate pixels of offset
    const scale = Math.pow(2, this.map.getZoom())
    const pixelOffset = new google.maps.Point(offsetX / scale, offsetY / scale)

    // estimate corrected point
    const newPoint = new google.maps.Point(
      point.x - pixelOffset.x,
      point.y + pixelOffset.y
    )

    return this.map.getProjection().fromPointToLatLng(newPoint)
  }

  @Debounce(300)
  navigateToSelected(zoom: number = navigationZoomBreakpoint): void {
    if (!this.$store.state.selectedElement) return

    this.$store.dispatch('navigationCenterChange', {
      zoomLevel: zoom,
      googleCoords: this.$store.state.selectedElement.coords,
    })
  }

  // adds a rectable of a given bound and the corresponding center point
  debugModeForBounds(bounds: any): void {
    if (process.env.VUE_APP_DEBUG_BOUNDS !== 'true') return

    if (this.debug.bounds) {
      this.debug.bounds.setBounds(bounds)
    } else {
      this.debug.bounds = new google.maps.Rectangle({
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        map: this.map,
        bounds: bounds,
      })
    }

    if (this.debug.center) {
      this.debug.center.setPosition(this.map.getCenter())
    } else {
      this.debug.center = new google.maps.Marker({
        position: this.map.getCenter(),
        map: this.map,
      })
    }
  }
  debugModeForSecondaryBounds(bounds: any): void {
    if (process.env.VUE_APP_DEBUG_BOUNDS !== 'true') return

    if (this.debug.secondaryBounds) {
      this.debug.secondaryBounds.setBounds(bounds)
    } else {
      this.debug.secondaryBounds = new google.maps.Rectangle({
        strokeColor: '#0fd666',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#0fd666',
        fillOpacity: 0.35,
        map: this.map,
        bounds: bounds,
      })
    }
  }
}
