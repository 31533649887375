<template>
  <CloseSvg class="white-close-button--icon" />
</template>

<script>
import CloseSvg from '@/assets/icons/close.svg'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  components: {
    CloseSvg,
  },
})
export default class WhiteCloseButton extends Vue {}
</script>

<style lang="scss">
.white-close-button--icon {
  cursor: pointer;
  position: absolute;
  width: 25px;
  z-index: 2;
  right: 10px;
  top: 10px;
  g {
    fill: white;
  }

  transition: all 0.5s ease;
}
#app:not(.mobile) .white-close-button--icon:hover {
  transform: scale(1.5, 1.5);
}
</style>
