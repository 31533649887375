const googleMapsReadyDeferreds = []

function defer() {
  const deferred = {
    promise: null,
    resolve: null,
    reject: null,
  }

  deferred.promise = new Promise((resolve, reject) => {
    deferred.resolve = resolve
    deferred.reject = reject
  })

  return deferred
}

export function injectGoogleMapsScriptTag(key, cb) {
  if (window.google && window.google.maps) {
    return
  }
  const src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&callback=googleMapsScriptCallback`

  const googleMapScript = document.createElement('script')
  googleMapScript.setAttribute('src', src)
  document.head.appendChild(googleMapScript)

  window.googleMapsScriptCallback = () => {
    whenGoogleMapsReady().then(() => cb(window.google.maps))
    onGogleMapsReadyDeferreds()
  }
}

export function whenGoogleMapsReady() {
  if (window.googleMapsLoaded) return Promise.resolve(true)

  const deferred = defer()
  googleMapsReadyDeferreds.push(deferred)
  return deferred.promise
}

export function onGogleMapsReadyDeferreds() {
  window.googleMapsLoaded = true
  googleMapsReadyDeferreds.forEach((deferred) => deferred.resolve())
}
