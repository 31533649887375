
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import ElementCommons from './mixins/ElementCommons'
import PlusSvg from '@/assets/icons/plus.svg'
import MinusSvg from '@/assets/icons/minus.svg'
import { SelectedElement } from '@/store'
import { PlaceResult } from '@/types/api'
import { FrameCommunication } from '@/helpers/FrameCommunication'
import { WindowFix } from '@/shims/window'

@Component({
  mixins: [ElementCommons],
  components: {
    PlusSvg,
    MinusSvg,
  },
})
export default class SliderElement extends Vue {
  showMore: boolean = false

  @Prop() result: PlaceResult
  get active() {
    return (
      this.$store.state.selectedElement &&
      this.$store.state.selectedElement.id === this.result.id
    )
  }

  elementSelected(): void {
    const selectedElement: SelectedElement = {
      id: this.result.id,
      coords: {
        latitude: parseFloat(this.result.address.latitude),
        longitude: parseFloat(this.result.address.longitude),
      },
    }

    this.$store.commit('setSelectedElement', selectedElement)
    FrameCommunication.postPlace(this.result)
  }

  showMoreToggle(): void {
    this.showMore = !this.showMore
    if (this.showMore) {
      FrameCommunication.postPlace(this.result)
      WindowFix.tracker.track('showMoreToggle', this.result)
    }
  }
}
