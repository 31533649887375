import { Component, Vue, Watch } from 'vue-property-decorator'
import { UserConsentOptState } from '@/types/user_consent'

@Component
export default class UserConsent extends Vue {
  userDidOpt: boolean = false
  userOptedIn: boolean = false

  created() {
    this.assignState()
    this.bypassConsent()
  }

  @Watch('$store.state.userOptedIn')
  updateUserOptIn() {
    this.assignState()
  }

  @Watch('$store.state.userConsentOptState')
  updateUserOptInState() {
    this.assignState()
  }

  @Watch('$store.state.clientInformation.consentSkipped')
  bypassConsent() {
    if (this.$store.state.clientInformation.consentSkipped) {
      this.userDidOpt = true
      this.userOptedIn = true
    }
  }

  assignState() {
    this.userDidOpt = this.checkOpt()
    this.userOptedIn = this.$store.state.userOptedIn
  }

  checkOpt() {
    return this.$store.state.userConsentOptState !== UserConsentOptState.None
  }
}
