
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import ButtonLink from './content/ButtonLink.vue'
import ContentRow from './content/ContentRow.vue'
import ContentRowWithHr from './content/ContentRowWithHr.vue'

import DividingLine from '../extras/DividingLine.vue'

import DistanceSvg from '@/assets/icons/distance.svg'
import CallSvg from '@/assets/icons/call.svg'
import MailSvg from '@/assets/icons/mail.svg'
import LinkSvg from '@/assets/icons/link.svg'
import OpenSvg from '@/assets/icons/open.svg'
import ClockSvg from '@/assets/icons/clock.svg'

import get from 'lodash.get'
import pickBy from 'lodash.pickby'
import { servicesMapper } from '@/mixins/apiContentDecorator'

import TransitionExpanded from '@/mixins/TransitionExpanded.vue'
import { PlaceResult } from '@/types/api'
import { getDistance } from 'geolib'

import { WindowFix } from '@/shims/window'
import {
  openingHoursAvailable,
  humanSortedOpeningHours,
  humanClosingInfo,
  humanNextOpeningInfo,
  isRoundTheClock,
} from '@/helpers/openingHoursLogic'

import dayjs from 'dayjs'

import {
  adaptedGenerateSeoGAAUrl,
  adaptedGenerateSeoPageUrl,
} from '@/helpers/VRDeStandortDetailUrl'

@Component({
  components: {
    ButtonLink,
    ContentRow,
    ContentRowWithHr,
    DistanceSvg,
    CallSvg,
    MailSvg,
    LinkSvg,
    OpenSvg,
    ClockSvg,
    TransitionExpanded,
    DividingLine,
  },
})
export default class Content extends Vue {
  @Prop() result: PlaceResult
  @Prop() viewMode: string
  @Prop() showMore: boolean

  vrTemplateBlank: boolean =
    this.$store.state.clientInformation.template === 'vr' &&
    this.result.kind === 'bank'
  showAllServices: boolean = false
  showAllTimes: boolean = false
  showRouteplannerLink: boolean = this.vrTemplateBlank
  showRouteplannerButton: boolean = !this.vrTemplateBlank
  showOpeningHoursHint: boolean = this.result.opening_hours_hint ? true : false

  private servicesMapper = servicesMapper

  updated(): void {
    if (!this.showMore) this.showAllServices = false
  }

  get showOpeningHoursRow(): boolean {
    if (!this.openingHoursAvailable && this.openingHoursHint) return false

    return this.showMore || this.viewMode == 'sidebar'
  }

  get formattedDistance(): string {
    if (!this.$store.state.searchInput) return null
    if (!this.$store.state.position || !this.result.address) return null

    const distance = getDistance(
      this.result.address,
      this.$store.state.position
    )

    if (distance < 1000) return `${distance} m`
    else return `${(distance / 1000).toFixed(1)} km`
  }

  get availableServices(): Array<string> {
    return <Array<string>>(
      pickBy(this.result.services, (element: string) => element)
    )
  }

  get availableServiceNames(): Array<string> {
    return <Array<string>>Object.keys(this.availableServices)
      .map((service: string) => servicesMapper(service, this.result.kind))
      .sort()
  }

  get openingHoursHint(): string {
    return this.result.opening_hours_hint
  }

  get name(): string {
    if (this.result.alternative_bank_name)
      return this.result.alternative_bank_name
    switch (this.result.kind) {
      case 'bank':
        return [
          get(this.result, 'institute.name'),
          this.result.branch_name,
        ].join(' ')
      case 'atm':
        return ['Geldautomat', get(this.result, 'institute.name')].join(' ')
      default:
        return this.result.name
    }
  }

  get isRoundTheClock() {
    return isRoundTheClock(this.result.opening_hours)
  }

  get openingHoursAvailable() {
    return openingHoursAvailable(this.result.opening_hours)
  }

  get humanSortedOpeningHours() {
    return humanSortedOpeningHours(this.result.opening_hours)
  }

  get humanNextOpeningInfo(): string {
    return humanNextOpeningInfo(this.result.opening_hours, dayjs())
  }

  get humanClosingInfo(): string {
    return humanClosingInfo(this.result.opening_hours, dayjs())
  }

  get renderService(): boolean {
    return Object.keys(this.availableServices).length > 0
  }

  get renderPhone(): boolean {
    return this.result.kind === 'bank' && !!this.result.contact.phone_area
  }

  get renderMail(): boolean {
    if (this.vrTemplateBlank) return false
    return this.result.kind === 'bank' && !!this.result.contact.email
  }

  get renderDetailPageUrl(): boolean {
    if (this.vrTemplateBlank) return false
    return this.result.links.detail_page_url && this.result.kind === 'bank'
  }

  get formattedAdress(): string {
    return (
      this.result.name +
      ',' +
      this.result.address.street +
      ',' +
      this.result.address.zip_code +
      ' ' +
      this.result.address.city
    )
  }

  get navigateToElement(): string {
    const lat = this.$store.state.place
      ? this.$store.state.place.latitude +
        ',' +
        this.$store.state.place.longitude
      : ''

    return (
      'https://www.google.com/maps/dir/' +
      lat +
      '/' +
      this.formattedAdress +
      '/@' +
      this.result.address.latitude +
      ',' +
      this.result.address.longitude +
      ',' +
      this.$store.state.google.zoomLevel +
      'z'
    )
  }

  get formularLink(): string {
    if (this.result.kind === 'bank')
      return adaptedGenerateSeoPageUrl(this.result) + '?openform=true'
    if (this.result.kind === 'atm')
      return adaptedGenerateSeoGAAUrl(this.result) + '?openform=true'
    return ''
  }

  get renderFormularLink(): boolean {
    if (!this.vrTemplateBlank) return false
    return this.result.kind === 'bank' || this.result.kind === 'atm'
  }

  get webLinkUrl(): string {
    if (this.result.deeplink) return this.result.deeplink
    return this.result.links.url
  }

  get renderGoToWebButton(): boolean {
    if (!this.vrTemplateBlank) return false
    return this.result.kind === 'bank' || this.result.kind === 'atm'
  }

  track(key: string, data?: any): void {
    WindowFix.tracker.track(key, data)
  }

  changeLocationToResult(): void {
    this.$emit('changeLocationToResult', this.result)
  }

  showOpeningHoursHintToggle(): void {
    this.showOpeningHoursHint = !this.showOpeningHoursHint
    if (this.showOpeningHoursHint)
      this.track('showOpeningHoursHint', this.result)
  }

  showAllTimesToggle(): void {
    if (this.openingHoursAvailable) this.showAllTimes = !this.showAllTimes
    if (this.showAllTimes) this.track('showAllOpeningTimes', this.result)
  }

  showAllServicesToggle(): void {
    this.showAllServices = !this.showAllServices
    if (this.showAllServices) this.track('showAllServices', this.result)
  }
}
