
import { Component, Prop, Vue } from 'vue-property-decorator'
import GoogleFormHeader from './GoogleFormHeader.vue' // @ is an alias to /src
import FormFilters from './FormFilters.vue' // @ is an alias to /src
import ResultSlider from './results/Slider.vue' // @ is an alias to /src

import WhiteCloseButton from '@/components/buttons/WhiteCloseButton.vue'
import FormHeader from '@/components/sidebar/FormHeader.vue'

@Component({
  name: 'TopbarPanelContent',
  components: {
    FormHeader,
    GoogleFormHeader,
    FormFilters,
    ResultSlider,
    WhiteCloseButton,
  },
})
export default class TopbarPanelContent extends Vue {
  @Prop() results: any
  @Prop() useGoogleMaps: boolean
  @Prop() initial: boolean
  @Prop() animationMoveSuccess: boolean
  @Prop() displayToggle: boolean

  showFormFilters: boolean = false

  formHeaderComponent() {
    return this.useGoogleMaps ? 'GoogleFormHeader' : 'FormHeader'
  }

  mounted(): void {
    this.$store.commit('setPlacesRequestMode', 'topbar')
  }
}
