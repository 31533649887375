import { ActivatableMapMarker } from '@/mixins/googleMaps/ActivatableMapMarker'
import { ActivatableMarkerElement } from '@/types/marker'
import { Component } from 'vue-property-decorator'

@Component({
  name: 'BankMarker',
})
export default class BankMarker
  extends ActivatableMapMarker
  implements ActivatableMarkerElement
{
  get markerCssClass(): string {
    switch (this.mainSubtype) {
      case 'vr':
      case 'sonstige':
        return 'map-marker-overlay--bank-vr'
      case 'sparda':
        return 'map-marker-overlay--bank-sparda'
      case 'psd':
        return 'map-marker-overlay--bank-psd'
      case 'bbbank':
        return 'map-marker-overlay--bank-bbbank'
      case 'liga':
        return 'map-marker-overlay--bank-liga'
      case 'pax':
        return 'map-marker-overlay--bank-pax'
      case 'kc':
        return 'map-marker-overlay--bank-kc'
      case 'be':
        return 'map-marker-overlay--bank-be'
    }
  }
}
