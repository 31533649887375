
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator'

import CheckboxFilter from './CheckboxFilter.vue'
import { servicesMapper } from '@/mixins/apiContentDecorator'
@Component({
  components: {
    CheckboxFilter,
  },
})
export default class ServiceFilters extends Vue {
  private servicesMapper = servicesMapper
  @Prop() content: { [key: string]: boolean }
}
