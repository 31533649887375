
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator'
import LupeSvg from '@/assets/icons/lupe.svg'
import LocationSvg from '@/assets/icons/location.svg'
import CloseSvg from '@/assets/icons/close.svg'
import FilterSvg from '@/assets/icons/Filter.svg'
import MinusSvg from '@/assets/icons/minus.svg'
import Geolocation from '@/mixins/googleMaps/navigator.geolocation'
import { LatitudeLongitude } from '@/types/marker'
import { Debounce, Bind } from 'lodash-decorators'
import { WindowFix } from '@/shims/window'

declare const google: any

@Component({
  name: 'GoogleFormHeader',
  mixins: [Geolocation],
  components: {
    LupeSvg,
    LocationSvg,
    CloseSvg,
    FilterSvg,
    MinusSvg,
  },
})
export default class GoogleFormHeader extends Vue {
  selectedTypes: Array<string> = ['bank']
  @Prop() showOptionButton: boolean
  @Prop() useGoogleMaps: boolean
  @Prop() initial: boolean
  @Prop() open: boolean

  center: any
  searchInput: string = ''
  focused: boolean = false
  places: any

  debounceFocusChange(focus: boolean) {
    setTimeout(() => (this.focused = focus), 150)
  }

  toogleInputFocus() {
    const element = this.getInputElementById('gSearch')
    if (this.focused) {
      this.googleMapsGeocoder()
      element.blur()
    } else element.focus()
  }

  @Watch('$store.state.searchInput')
  searchInputWatcher2(val: string, oldVal: string) {
    if (this.searchInput === val) return
    this.searchInput = val
  }

  async googleIsLoaded(): Promise<any> {
    const isLoaded = () => {
      return (
        this.$store.state.googleMapsLoaded &&
        'undefined' !== WindowFix.google &&
        'undefined' !== WindowFix.google.maps
      )
    }

    let retryCount = 50
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (isLoaded()) {
          clearInterval(interval)
          resolve(WindowFix.google.maps)
        } else {
          if (--retryCount === 0) {
            reject('no google maps!')
          }
        }
      }, 50)
    })
  }

  beforeMount() {
    this.searchInput =
      this.$store.state.searchInput ||
      this.$store.state.clientInformation.requestedAddress ||
      this.$route.query['city'] ||
      ''
  }

  async mounted() {
    console.log('GoogleFormHeader mounted')
    const maps = await this.googleIsLoaded()
    const input = this.getInputElementById('gSearch')

    this.places = new maps.places.Autocomplete(input, {
      componentRestrictions: { country: 'de' },
    })
    this.places.setFields(['formatted_address', 'geometry'])
    this.places.addListener('place_changed', this.inputChange)

    if (this.searchInput.length) {
      maps.event.trigger(this.places, 'place_changed')
      this.googleMapsGeocoder()
    }
  }

  @Emit()
  toggleFilters(): void {}

  @Debounce(100)
  @Bind
  inputChange(): void {
    const place = this.places.getPlace()
    if (place === undefined) return
    else if (Object.keys(place).length <= 1) {
      this.googleMapsGeocoder()
    } else {
      this.searchInput = place.formatted_address
      this.publishLocalInformation(place)
    }
    WindowFix.tracker.track('addressInputSearch')
  }

  private publishLocalInformation(place: any) {
    const position: LatitudeLongitude = {
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    }
    if (this.searchInput !== this.$store.state.searchInput)
      this.$store.commit('setSearchInput', this.searchInput)
    this.$store.dispatch('changePosition', position)

    this.$emit('input-change', this.searchInput)
  }

  private googleMapsGeocoder() {
    new google.maps.Geocoder().geocode(
      { address: this.searchInput },
      (results: Array<any>, status: string) => {
        if (status == 'OK') {
          this.publishLocalInformation(results[0])
        } else {
          // foo
        }
      }
    )
  }

  clearInput() {
    this.searchInput = ''
    const element = this.getInputElementById('gSearch')
    element.focus()
  }

  getInputElementById(value: string): HTMLInputElement {
    return <HTMLInputElement>document.getElementById(value)
  }

  toggleType(type: string): void {
    if (this.selectedTypes.includes(type))
      this.selectedTypes = this.selectedTypes.filter((e) => e !== type)
    else this.selectedTypes.push(type)
  }
}
